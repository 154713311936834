import Vue from "vue";
import service from "@/store/services/transfer-product-service";

const state = {
  isVisibleDialog: false,
  isVisibleShow: false,
  isVisibleDeleteDialog: false,
  isLoadingTransfers: false,
  isLoadingTransfer: false,

  currentTab: 1,

  editedTransfer: {},
  transfer: {},

  filters: {},

  listTransfers: [],
  meta: {},
};

const mutations = {
  CURRENT_TAB(state, currentTab) {
    Vue.set(state, "currentTab", currentTab);
  },
  IS_VISIBLE_DIALOG(state, is_visible) {
    Vue.set(state, "isVisibleDialog", is_visible);
  },

  IS_VISIBLE_DELETE_DIALOG(state, is_visible) {
    Vue.set(state, "isVisibleDeleteDialog", is_visible);
  },

  IS_VISIBLE_SHOW(state, is_visible) {
    Vue.set(state, "isVisibleShow", is_visible);
  },

  EDITED_TRANSFER(state, transfer) {
    Vue.set(state, "editedTransfer", transfer);
  },

  TRANSFER(state, transfer) {
    Vue.set(state, "transfer", transfer);
  },

  EDITED_PRODUCTS(state, products) {
    Vue.set(state.editedTransfer, "products", products);
  },

  SET_TRANSFERS(state, transfers) {
    state.listTransfers = transfers;
  },

  ADD_TRANSFER(state, transfer) {
    state.listTransfers.push(transfer);
  },

  CLEAN_LIST(state) {
    Vue.set(state, "listTransfers", []);
  },

  UPDATE_TRANSFER(state, transfer) {
    const item = state.listTransfers.find((item) => item.id === transfer.id);
    Object.assign(item, transfer);
  },

  DELETE_TRANSFER(state, transfer) {
    state.listTransfers.splice(
      state.listTransfers.map((item) => item.id).indexOf(transfer.id),
      1
    );
  },

  SET_META(state, data) {
    state.meta.page = data.meta.current_page;
    state.meta.itemsPerPage = data.meta.per_page;
    state.meta.lastPage = data.meta.last_page;
    state.meta.totalItems = data.meta.total;
  },

  SET_FILTERS(state, filters) {
    Vue.set(state, "filters", filters);
  },
};

const actions = {
  openEditForm: (store, transfer) => {
    store.commit("IS_VISIBLE_DIALOG", true);
    store.commit("EDITED_TRANSFER", Object.assign({}, transfer));
  },

  openDeleteForm: (store, transfer) => {
    store.commit("IS_VISIBLE_DELETE_DIALOG", true);
    store.commit("EDITED_TRANSFER", Object.assign({}, transfer));
  },

  openShowForm: (store, transfer) => {
    store.commit("IS_VISIBLE_SHOW", true);
    store.commit("TRANSFER", Object.assign({}, transfer));
  },

  closeForm: (store) => {
    store.commit("IS_VISIBLE_DIALOG", false);
    store.commit("IS_VISIBLE_DELETE_DIALOG", false);
    store.commit("IS_VISIBLE_SHOW", false);
    store.commit("EDITED_TRANSFER", {});
    store.commit("TRANSFER", {});
  },

  show: (store, params) => {
    return service.show(params).then((transfer) => {
      store.commit("TRANSFER", Object.assign({}, transfer));
    });
  },

  list: (store, params) => {
    store.state.isLoadingTransfers = true;
    return service.list(params).then((data) => {
      store.commit("SET_TRANSFERS", data.transfers);
      store.commit("SET_META", data);
      store.state.isLoadingTransfers = false;
    });
  },

  getInvoice: (store, transfer) => {
    return service.getInvoice(transfer);
  },

  getDeliveryNote: (store, transfer) => {
    return service.getDeliveryNote(transfer);
  },

  add: (store, transfer) => {
    return service.add(transfer).then((item) => {
      store.commit("ADD_TRANSFER", item);
      store.dispatch("list", {
        page: 1,
        itemsPerPage: 30,
        transfer_status_name: "open",
      });
    });
  },

  addToCart: (store, params) => {
    return service.addToCart(params).then((transfer) => {
      store.commit("TRANSFER", transfer);
    });
  },

  removeFromCart: (store, params) => {
    return service.removeFromCart(params).then((transfer) => {
      store.commit("TRANSFER", transfer);
    });
  },

  update: (store, transfer) => {
    return service.update(transfer).then((item) => {
      store.commit("TRANSFER", item);
    });
  },

  validateReception: (store, transfer) => {
    return service.validateReception(transfer).then((item) => {
      store.commit("DELETE_TRANSFER", item);
    });
  },

  cancel: (store, transfer) => {
    return service.cancelTransfer(transfer).then(() => {
      store.commit("DELETE_TRANSFER", transfer);
    });
  },

  destroy: (store, transfer) => {
    return service.destroy(transfer).then(() => {
      store.commit("DELETE_TRANSFER", transfer);
    });
  },
  uploadCSV: (store, payload) => {
    return service.uploadCSV(payload).then((data) => {
      store.commit("SET_TRANSFERS", data.transfers);
      store.commit("SET_META", data);
    });
  },
  exportCSV: (store, option) => {
    return service.exportCSV(option);
  },
  exportCSVResult: (store, option) => {
    return service.exportCSVResult(option);
  },
  setFilters: (store, payload) => {
    store.commit("SET_FILTERS", payload);
  },

  createFromExpired: (store, payload) => {
    return service.createFromExpired(payload).then((transfer) => {
      store.commit("TRANSFER", Object.assign({}, transfer));
      return transfer;
    });
  },
};

const getters = {
  currentTab(state) {
    return state.currentTab;
  },
  isVisibleDialog(state) {
    return state.isVisibleDialog;
  },

  isLoadingTransfers(state) {
    return state.isLoadingTransfers;
  },

  isLoadingTransfer(state) {
    return state.isLoadingTransfer;
  },

  editedIndex(state) {
    return state.editedIndex;
  },

  editedTransfer(state) {
    return state.editedTransfer;
  },

  transfer(state) {
    return state.transfer;
  },

  list(state) {
    return state.listTransfers;
  },

  openTransfers(state) {
    return state.listTransfers.filter((item) => {
      return item.transferStatus.name === "open";
    });
  },

  meta(state) {
    return state.meta;
  },

  filters(state) {
    return state.filters;
  },
};

const transferProducts = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default transferProducts;
