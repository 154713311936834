import axios from "@/helpers/axios";
import authService from "@/store/services/auth-service";

const base_url = process.env.VUE_APP_API_BASE_URL;

function list(forClass) {
  return axios
    .get(`${base_url}payment_methods`, {
      params: { for_class: forClass },
      headers: authService.authHeader(),
    })
    .then((response) => {
      return response.data.payment_methods;
    });
}

function add(payment_method) {
  return axios
    .post(`${base_url}payment_methods`, payment_method, {
      headers: authService.authHeader(),
    })
    .then((response) => {
      return response.data.payment_method;
    });
}

function update(payment_method) {
  return axios
    .put(`${base_url}payment_methods/${payment_method.id}`, payment_method, {
      headers: authService.authHeader(),
    })
    .then((response) => {
      return response.data.payment_method;
    });
}

function destroy(payment_method) {
  return axios
    .delete_method(`${base_url}payment_methods/${payment_method.id}`, {
      headers: authService.authHeader(),
    })
    .then((response) => {
      return response.data.payment_method;
    });
}

export default {
  list,
  add,
  update,
  destroy,
};
