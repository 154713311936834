import axios from "@/helpers/axios";
import authService from "@/store/services/auth-service";

const base_url = process.env.VUE_APP_API_BASE_URL;

function list(option) {
  return axios
    .get(`${base_url}lots`, {
      params: option,
      headers: authService.authHeader(),
    })
    .then((response) => {
      return response.data;
    });
}

async function updatePrice(data) {
  const res = await axios.put(
    `${base_url}lots/${data.lot_id}/update-price`,
    data,
    {
      headers: authService.authHeader(),
    }
  );
  return res.data.lot;
}

async function exportCSV(option) {
  const res = await axios.get(`${base_url}lots/export/csv`, {
    params: option,
    headers: authService.authHeader(),
    responseType: "blob",
  });
  return res.data;
}

export default {
  list,
  updatePrice,
  exportCSV,
};
