import axios from "@/helpers/axios";
import authService from "@/store/services/auth-service";

const base_url = process.env.VUE_APP_API_BASE_URL;

async function add(stockAdjustment) {
  const res = await axios.post(
    `${base_url}stock-adjustments`,
    stockAdjustment,
    {
      headers: authService.authHeader(),
    }
  );
  return res.data.stock_adjustment;
}

async function addProductWithLot(params) {
  const { stockAdjustment, product, lots } = params;
  const res = await axios.put(
    `${base_url}stock-adjustments/${stockAdjustment.id}/${product.id}/addProduct`,
    { lots: lots },
    {
      headers: authService.authHeader(),
    }
  );
  return res.data.stock_adjustment;
}

async function removeFromCart(stockItem) {
  const res = await axios.delete_method(
    `${base_url}stock-adjustments/${stockItem.stock_adjustment_id}/products/${stockItem.product_id}`,
    {
      headers: authService.authHeader(),
    }
  );
  return res.data.stock_adjustment;
}

async function list(params) {
  const res = await axios.get(`${base_url}stock-adjustments`, {
    params: params,
    headers: authService.authHeader(),
  });
  return res.data;
}

async function getAdjustmentSlip(stockAdjustment) {
  const res = await axios.get(
    `${base_url}generate_stock_adjustment_invoice_a4/${stockAdjustment.id}`,
    { headers: authService.authHeader(), responseType: "blob" }
  );
  return res.data;
}

async function submit(params) {
  const res = await axios.put(
    `${base_url}stock-adjustments/submit/${params.stock_adjustment_id}`,
    {},
    {
      headers: authService.authHeader(),
    }
  );
  return res.data;
}

async function show(options) {
  const res = await axios.get(
    `${base_url}stock-adjustments/${options.id}?include=${options.include}`,
    {
      headers: authService.authHeader(),
    }
  );
  return res.data.stockAdjustment;
}

function destroy(stockAdjustment) {
  return axios.delete_method(
    `${base_url}stock-adjustments/${stockAdjustment.id}`,
    {
      headers: authService.authHeader(),
    }
  );
}

function update_status(stockAdjustment) {
  return axios.put(
    `${base_url}stock-adjustments/update_status/${stockAdjustment.id}`,
    {
      stockAdjustment,
    },
    {
      headers: authService.authHeader(),
    }
  );
}

async function update(stockAdjustment) {
  const res = await axios.put(
    `${base_url}stock-adjustments/${stockAdjustment.id}`,
    stockAdjustment,
    { headers: authService.authHeader() }
  );
  return res.data.stockAdjustment;
}

async function validate(stockAdjustment) {
  const res = await axios.put(
    `${base_url}stock-adjustments/validate/${stockAdjustment.id}`,
    {},
    {
      headers: authService.authHeader(),
    }
  );
  return res.data;
}

async function uploadCSVStockAdjustment(csv) {
  const res = await axios.post(
    `${base_url}import-stock-adjustments`,
    { file: csv },
    {
      headers: {
        "Content-Type": "multipart/form-data",
        ...authService.authHeader(),
      },
    }
  );
  return res.data;
}

async function downloadTemplate() {
  const res = await axios.get(`${base_url}stock-adjustments/import-template`, {
    headers: authService.authHeader(),
    responseType: "blob",
  });
  return res.data;
}

async function downloadCSV(option) {
  const res = await axios.get(`${base_url}stock_adjustments_export_csv`, {
    params: option,
    headers: authService.authHeader(),
    responseType: "blob",
  });
  return res.data;
}

async function downloadFailedResult(name) {
  const res = await axios.get(
    `${base_url}stock-adjustments/import_errors_file/${name}`,
    {
      headers: authService.authHeader(),
      responseType: "blob",
    }
  );
  return res.data;
}

async function downloadCSVResult(option) {
  const res = await axios.get(
    `${base_url}stock_adjustments_export_result_csv`,
    {
      params: option,
      headers: authService.authHeader(),
      responseType: "blob",
    }
  );
  return res.data;
}

export default {
  add,
  addProductWithLot,
  removeFromCart,
  list,
  getAdjustmentSlip,
  submit,
  show,
  destroy,
  validate,
  update_status,
  update,
  uploadCSVStockAdjustment,
  downloadTemplate,
  downloadFailedResult,
  downloadCSV,
  downloadCSVResult,
};
