import service from "@/store/services/payment-method-service";

const state = {
  isLoadingMethods: false,
  listMethods: [],
};

const mutations = {
  SET_METHODS: (state, methods) => {
    state.listMethods = methods;
  },
};

const actions = {
  list: (store, forClass) => {
    store.state.isLoadingMethods = true;
    return service.list(forClass).then((methods) => {
      store.commit("SET_METHODS", methods);
      store.state.isLoadingMethods = false;
    });
  },
};

const getters = {
  isLoadingMethods(state) {
    return state.isLoadingMethods;
  },

  listMethods(state) {
    return state.listMethods;
  },
};

const paymentMethods = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default paymentMethods;
