export default class Coupon {
  constructor({
    type,
    amount,
    percentage,
    code,
    max_off,
    target_amount,
    max_usage,
    max_usage_per_user,
    max_usage_per_day,
    total_usage,
    start_at,
    end_at,
    status,
    hub_id,
  }) {
    this.type = type;
    this.amount = amount;
    this.percentage = percentage;
    this.code = code;
    this.max_off = max_off;
    this.target_amount = target_amount;
    this.max_usage = max_usage;
    this.max_usage_per_user = max_usage_per_user;
    this.max_usage_per_day = max_usage_per_day;
    this.total_usage = total_usage;
    this.start_at = start_at;
    this.end_at = end_at;
    this.status = status;
    this.hub_id = hub_id;
  }
}
