import Vue from "vue";
import service from "@/store/services/user-service";

const state = {
  isVisibleDialog: false,
  isVisibleDeleteDialog: false,
  isVisibleInactivateUserDialog: false,
  isVisibleUpdatePWDialog: false,

  editedUser: {},
  listUsers: [],
  meta: {},
  selectedHubIds: [],
};

const mutations = {
  IS_VISIBLE_DIALOG(state, is_visible) {
    Vue.set(state, "isVisibleDialog", is_visible);
  },

  IS_VISIBLE_DELETE_DIALOG(state, is_visible) {
    Vue.set(state, "isVisibleDeleteDialog", is_visible);
  },

  IS_VISIBLE_INACTIVATE_USER_DIALOG(state, is_visible) {
    Vue.set(state, "isVisibleInactivateUserDialog", is_visible);
  },

  IS_VISIBLE_UPDATE_PW_DIALOG(state, is_visible) {
    Vue.set(state, "isVisibleUpdatePWDialog", is_visible);
  },

  EDITED_USER(state, user) {
    Vue.set(state, "editedUser", user);
  },

  SET_USERS(state, users) {
    Vue.set(state, "listUsers", users);
  },

  SET_META(state, data) {
    if (data.meta) {
      state.meta.page = data.meta.current_page;
      state.meta.itemsPerPage = data.meta.per_page;
      state.meta.lastPage = data.meta.last_page;
      state.meta.totalItems = data.meta.total;
    }
  },

  ADD_USER(state, user) {
    state.listUsers.push(Object.assign({}, user));
  },

  UPDATE_USER(state, user) {
    const item = state.listUsers.find((item) => item.id === user.id);
    Object.assign(item, user);
  },

  DELETE_USER(state, user) {
    state.listUsers.splice(
      state.listUsers.map((o) => o.id).indexOf(user.id),
      1
    );
  },
};

const actions = {
  openEditForm: (store, user) => {
    store.commit("IS_VISIBLE_DIALOG", true);
    store.commit("EDITED_USER", Object.assign({}, user));
  },

  openDeleteForm: (store, user) => {
    store.commit("IS_VISIBLE_DELETE_DIALOG", true);
    store.commit("EDITED_USER", Object.assign({}, user));
  },

  openInactivateUserForm: (store, user) => {
    store.commit("IS_VISIBLE_INACTIVATE_USER_DIALOG", true);
    var user_data = (({ id, status, reason }) => ({ id, status, reason }))(
      user
    );
    user_data.status = "inactive";
    store.commit("EDITED_USER", user_data);
  },

  openUpdatePWForm: (store, user) => {
    store.commit("IS_VISIBLE_UPDATE_PW_DIALOG", true);
    var user_data = (({ id }) => ({ id }))(user);
    store.commit("EDITED_USER", user_data);
  },

  closeForm: (store) => {
    store.commit("IS_VISIBLE_DIALOG", false);
    store.commit("IS_VISIBLE_DELETE_DIALOG", false);
    store.commit("IS_VISIBLE_INACTIVATE_USER_DIALOG", false);
    store.commit("IS_VISIBLE_UPDATE_PW_DIALOG", false);
    store.commit("EDITED_USER", {});
  },

  list: (store, params) => {
    return service.list(params).then((data) => {
      store.commit("SET_USERS", data.users);
      store.commit("SET_META", data);
    });
  },

  add: (store, user) => {
    return service.add(user).then((user) => {
      store.commit("ADD_USER", user);
      store.dispatch("list", { page: 1, itemsPerPage: 30 });
    });
  },

  update: (store, user) => {
    return service.update(user).then((user) => {
      store.commit("UPDATE_USER", user);
    });
  },

  destroy: (store) => {
    if (store.state.editedUser) {
      const user = store.state.editedUser;
      return service.destroy(user).then(() => {
        store.commit("DELETE_USER", user);
      });
    }
  },

  updateStatus: (store, user) => {
    return service.updateStatus(user).then(() => {
      store.commit("UPDATE_USER", user);
    });
  },

  updatePW: (store, user) => {
    return service.updatePW(user);
  },
};

const getters = {
  isVisibleDialog(state) {
    return state.isVisibleDialog;
  },

  editedUser(state) {
    return state.editedUser;
  },

  listUsers(state) {
    return state.listUsers;
  },

  meta(state) {
    return state.meta;
  },
};

const users = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default users;
