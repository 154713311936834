import axios from "@/helpers/axios";
import authService from "@/store/services/auth-service";

const base_url = process.env.VUE_APP_API_BASE_URL;

function list(option) {
  return axios
    .get(`${base_url}sync-order-logs`, {
      params: option,
      headers: authService.authHeader(),
    })
    .then((response) => {
      return response.data;
    });
}
function listUnsyncedOrders(option) {
  return axios
    .get(`${base_url}unsynced-order-logs`, {
      params: option,
      headers: authService.authHeader(),
    })
    .then((response) => {
      return response.data;
    });
}

async function syncOrder(logId) {
  try {
    const response = await axios.put(
      `${base_url}sync-order-log/${logId}`,
      {},
      {
        headers: authService.authHeader(),
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error?.response?.data?.message || error?.message);
  }
}

export default {
  list,
  listUnsyncedOrders,
  syncOrder,
};
