import service from "@/store/services/brand-service";

const state = {
  isLoadingBrands: false,
  listBrands: [],
  meta: {},
};

const mutations = {
  SET_BRANDS(state, list_brands) {
    state.listBrands = list_brands;
  },

  ADD_BRAND(state, brand) {
    state.listBrands.push(brand);
  },

  UPDATE_BRAND(state, brand) {
    const item = state.listBrands.find((item) => item.id === brand.id);
    Object.assign(item, brand);
  },

  DELETE_BRAND(state, brand) {
    state.listBrands.splice(
      state.listBrands.map((o) => o.id).indexOf(brand.id),
      1
    );
  },

  SET_META(state, data) {
    if (data.meta) {
      state.meta.page = data.meta.current_page;
      state.meta.itemsPerPage = data.meta.per_page;
      state.meta.lastPage = data.meta.last_page;
      state.meta.totalItems = data.meta.total;
    }
  },
};

const actions = {
  list: (store, params) => {
    store.state.isLoadingBrands = true;
    service
      .list(params)
      .then((data) => {
        store.commit("SET_BRANDS", data.brands);
        store.commit("SET_META", data);
        store.state.isLoadingBrands = false;
      })
      .catch((error) => {
        store.state.isLoadingBrands = false;
        throw error;
      });
  },

  add: (store, brand) => {
    return service.add(brand).then((brand) => {
      store.commit("ADD_BRAND", brand);
      store.dispatch("list", { page: 1, itemsPerPage: 30 });
    });
  },

  update: (store, brand) => {
    return service.update(brand).then((brand) => {
      store.commit("UPDATE_BRAND", brand);
    });
  },

  destroy: (store, brand) => {
    return service.destroy(brand).then(() => {
      store.commit("DELETE_BRAND", brand);
    });
  },
};

const getters = {
  isLoadingBrands(state) {
    return state.isLoadingBrands;
  },
  listBrands(state) {
    return state.listBrands;
  },
  activeBrands(state) {
    return state.listBrands.filter((item) => {
      return item.status == "active";
    });
  },
  meta(state) {
    return state.meta;
  },
};

const brands = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default brands;
