import Vue from "vue";
import Vuetify from "vuetify/lib";
import i18n from "@/i18n";
import "@/sass/overrides.sass";
import iconDownloadPO from "@/components/icons/DownloadPO";
import iconViewItem from "@/components/icons/ViewItem";
import iconDeleteItem from "@/components/icons/DeleteItem";
import iconEditItem from "@/components/icons/EditItem";
import iconCopyItem from "@/components/icons/CopyItem";

import iconDownloadSlip from "@/components/icons/DownloadSlip";
import iconDownload from "@/components/icons/Download";
import iconRestoreItem from "@/components/icons/RestoreItem";
import iconDeactivateItem from "@/components/icons/DeactivateItem";
import iconPromotion from "@/components/icons/Promotion";
import iconRemove from "@/components/icons/Remove";

Vue.use(Vuetify);

const theme = {
  // primary: '#4CAF50','#5E2FBE','#7D6DDC', '#FED700'
  primary: "#5E2FBE",
  secondary: "#7D6DDC",
  accent: "#FED700",
  info: "#00CAE3",
};

export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
  theme: {
    themes: {
      dark: theme,
      light: theme,
    },
  },
  icons: {
    values: {
      iconDownloadSlip: {
        component: iconDownloadSlip,
      },
      iconViewItem: {
        component: iconViewItem,
      },
      iconRestoreItem: {
        component: iconRestoreItem,
      },
      iconDownloadPO: {
        component: iconDownloadPO,
      },
      iconDownload: {
        component: iconDownload,
      },
      iconCopyItem: {
        component: iconCopyItem,
      },
      iconDeleteItem: {
        component: iconDeleteItem,
      },
      iconDeactivateItem: {
        component: iconDeactivateItem,
      },
      iconEditItem: {
        component: iconEditItem,
      },
      iconPromotion: {
        component: iconPromotion,
      },
      iconRemove: {
        component: iconRemove,
      },
    },
  },
});
