import dbService from "@/store/services/localdb-service";
const state = {};

const mutations = {};

const actions = {
  syncOrders: ({ dispatch, commit, rootGetters }) => {
    if (rootGetters["orders/isSendingAddPos"] === false) {
      dbService.getOrders().then(async (orders) => {
        if (orders) {
          for (let i = 0; i < orders.length; i++) {
            const order = orders[i];
            commit("orders/IS_SENDING_ADD_POS", true, { root: true });
            if (order.products && navigator.onLine) {
              try {
                await dispatch("orders/addPos", order, { root: true });
                dbService.deleteOrderByTrackingNumber(
                  order.tracking_number_ext
                );
              } catch (error) {
                if (error.code !== "ECONNABORTED") {
                  const data = {
                    failed: 1,
                    error:
                      error.response?.data?.errors ??
                      error.response?.data?.message,
                  };
                  dbService.updateOrderByTrackingNumber(
                    order.tracking_number_ext,
                    data
                  );

                  dbService.updateOrderHistoryStatus(
                    order.tracking_number_ext,
                    "Failed"
                  );
                  // log error to server
                  if (!order.logged) {
                    dispatch("orders/logErrorPos", order, {
                      root: true,
                    }).then(() => {
                      dbService.markOrderAsLogged(order.tracking_number_ext);
                    });
                  }
                }
              }
            }
          }
        }
        commit("orders/IS_SENDING_ADD_POS", false, { root: true });
      });
    }
  },
};

const getters = {};

const sync = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default sync;
