import Vue from "vue";

const user = JSON.parse(localStorage.getItem("user"));
const permissions = Object.values(user?.permissions ?? []);
// const stores = Object.values(user?.stores ?? [])

function can(permission) {
  return permissions.includes(permission);
}

function hasAny(permissions_array) {
  for (let i = 0; i < permissions_array.length; i++) {
    if (permissions.includes(permissions_array[i])) {
      return true;
    }
  }

  return false;
}

function hasSub(sub_permission) {
  for (let i = 0; i < permissions.length; i++) {
    if (permissions[i].includes(sub_permission)) {
      return true;
    }
  }

  return false;
}

function hasAccessTo(model) {
  const match = permissions.findIndex((element) => {
    if (element.includes(model)) {
      return true;
    }
  });

  if (match > -1) {
    return true;
  }
  return false;
}

const admin = {
  permissions,
  can,
  hasAccessTo,
  hasAny,
  hasSub,
};
Vue.prototype.$admin = admin;
export default admin;
