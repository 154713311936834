import service from "@/store/services/type-price-service";

const state = {
  isLoading: false,
  list: [],
};

const mutations = {
  SET_TYPE_PRICE: (state, payload) => {
    state.list = payload;
  },
};

const actions = {
  list: (store) => {
    store.state.isLoading = true;
    return service.list().then((payload) => {
      store.commit("SET_TYPE_PRICE", payload);
      store.state.isLoading = false;
    });
  },
};

const getters = {
  isLoading(state) {
    return state.isLoading;
  },
  getTypePrices(state) {
    return state.list;
  },
};

const typePrices = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default typePrices;
