import Vue from "vue";
import service from "@/store/services/stock-management-service";

const state = {
  isLoadingStockManagements: false,

  stockManagements: {},

  list: [],

  meta: {},
  params: {},
  productsList: [],
  filters: {},
  selected: 0,
};

const mutations = {
  CLEAN_PRODUCTS_LIST(state) {
    Vue.set(state, "productsList", []);
  },
  SET_LIST(state, products) {
    Vue.set(state, "list", products);
  },

  SET_STOCK_MANAGEMENT(state, stockManagements) {
    state.list = stockManagements;
    state.productsList = stockManagements;
  },

  SET_META(state, data) {
    state.meta.page = data.meta.current_page;
    state.meta.itemsPerPage = data.meta.per_page;
    state.meta.lastPage = data.meta.last_page;
    state.meta.totalItems = data.meta.total;
    state.meta.manual_cancellation = data.meta.manual_cancellation;
  },

  SET_PARAMS(state, payload) {
    Vue.set(state, "params", payload);
  },

  SET_FILTERS(state, filters) {
    Vue.set(state.supplyOrder, "filters", filters);
  },

  SET_SELECTED(state, selected) {
    state.selected = selected;
  },

  CLEAN_LIST(state) {
    Vue.set(state, "list", []);
  },
};

const actions = {
  setParams: (store, params) => {
    store.commit("SET_PARAMS", params);
  },

  list: (store, params) => {
    store.state.isLoadingStockManagements = true;
    return service
      .list(params)
      .then((data) => {
        store.commit("SET_STOCK_MANAGEMENT", data.stockManagement);
        store.commit("SET_META", data);
        store.state.isLoadingStockManagements = false;
      })
      .catch((error) => {
        store.state.isLoadingStockManagements = false;
        throw error;
      });
  },

  exportResultCSV: (store, option) => {
    return service.exportResultCSV(option);
  },
  exportDetailstCSV: (store, option) => {
    return service.exportDetailstCSV(option);
  },
};

const getters = {
  isLoadingStockManagements(state) {
    return state.isLoadingStockManagements;
  },

  productsList(state) {
    return state.productsList;
  },

  meta(state) {
    return state.meta;
  },
  params(state) {
    return state.params;
  },
  filters(state) {
    return state.filters;
  },
  selected(state) {
    return state.selected;
  },

  list(state) {
    return state.list;
  },
};

const stockManagement = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default stockManagement;
