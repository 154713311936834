import axios from "@/helpers/axios";
import authService from "@/store/services/auth-service";
import Vue from "vue";

const base_url = process.env.VUE_APP_API_BASE_URL;
const STATUS_DELIVERED = "delivered";
const STATUS_RETURNED = "returned";
const STATUS_PARTIALLY_RETURNED = "partially returned";

async function list(params) {
  const res = await axios.get(`${base_url}orders`, {
    params: params,
    headers: authService.authHeader(),
  });
  return res.data;
}

async function autoComplete(search) {
  const res = await axios.get(`${base_url}orders/autocomplete`, {
    params: search,
    headers: authService.authHeader(),
  });
  return res.data;
}

async function types() {
  const res = await axios.get(`${base_url}orders-types`, {
    headers: authService.authHeader(),
  });
  return res.data;
}

async function show(options) {
  const res = await axios.get(`${base_url}orders/${options.id}`, {
    headers: authService.authHeader(),
  });
  return res.data.order;
}

async function getInvoice(order) {
  const res = await axios.get(`${base_url}generate_invoice_a4/${order.id}`, {
    headers: authService.authHeader(),
    responseType: "blob",
  });
  return res.data;
}
async function getDeliveryNote(order) {
  const res = await axios.get(
    `${base_url}generate_delivery_note_a4/${order.id}`,
    {
      headers: authService.authHeader(),
      responseType: "blob",
    }
  );
  return res.data;
}
async function getPosInvoice(order) {
  const res = await axios.get(`${base_url}generate_invoice_a7/${order.id}`, {
    headers: authService.authHeader(),
    responseType: "blob",
  });
  return res.data;
}
async function getPosCheckoutA7() {
  const res = await axios.get(`${base_url}pos/generate-checkout-a7`, {
    headers: authService.authHeader(),
    responseType: "blob",
  });
  return res.data;
}
async function getFilteredCheckoutA4(filter) {
  const res = await axios.get(`${base_url}pos/get-filtered-checkout-a4`, {
    params: filter,
    headers: authService.authHeader(),
    responseType: "blob",
  });
  return res.data;
}

async function add(order) {
  const res = await axios.post(`${base_url}orders`, order, {
    headers: authService.authHeader(),
  });
  return res.data.order;
}
async function addPos(order) {
  const res = await axios.post(`${base_url}pos/store`, order, {
    headers: authService.authHeader(),
  });
  return res.data.order;
}

async function logErrorPos(order) {
  const res = await axios.post(`${base_url}pos/log`, order, {
    headers: authService.authHeader(),
  });
  return res.data;
}

async function update(order) {
  const res = await axios.put(`${base_url}orders/${order.id}`, order, {
    headers: authService.authHeader(),
  });
  return res.data.order;
}

async function updateStatus(params) {
  const res = await axios.put(
    `${base_url}orders/status/${params.order_id}`,
    params,
    { headers: authService.authHeader() }
  );
  return res.data.order;
}

function destroy(order) {
  return axios.delete_method(`${base_url}orders/${order.id}`, {
    headers: authService.authHeader(),
  });
}

async function uploadCSV(csv) {
  const res = await axios.post(`${base_url}orders/upload_csv`, csv, {
    headers: {
      "Content-Type": "multipart/form-data",
      ...authService.authHeader(),
    },
  });
  return res.data;
}

async function downloadCSV(option) {
  const res = await axios.get(`${base_url}orders_export_csv`, {
    params: option,
    headers: authService.authHeader(),
    responseType: "blob",
  });
  return res.data;
}

async function exportOutboundList(option) {
  const res = await axios.get(`${base_url}outbound-list/export`, {
    params: option,
    headers: authService.authHeader(),
    responseType: "blob",
  });
  return res.data;
}
async function exportResultCSV(option) {
  const res = await axios.get(`${base_url}orders/export-csv/result`, {
    params: option,
    headers: authService.authHeader(),
    responseType: "blob",
  });
  return res.data;
}

function canGetInvoice(item) {
  return Vue.prototype.$admin.can("order-view") &&
    [STATUS_DELIVERED, STATUS_RETURNED, STATUS_PARTIALLY_RETURNED].includes(
      item.order_status.name
    )
    ? true
    : false;
}

async function refundProducts(order) {
  const res = await axios.post(`${base_url}orders/${order.id}/refund`, order, {
    headers: authService.authHeader(),
  });
  return res.data;
}

async function getOrderForRefund(options) {
  const res = await axios.get(`${base_url}orders/${options.id}/refund`, {
    headers: authService.authHeader(),
  });
  return res.data.order;
}

async function returnOrder(orderId) {
  const res = await axios.put(
    `${base_url}orders/${orderId}/return`,
    {},
    {
      headers: authService.authHeader(),
    }
  );
  return res.data.order;
}

export default {
  list,
  autoComplete,
  types,
  show,
  getInvoice,
  getDeliveryNote,
  getPosInvoice,
  getPosCheckoutA7,
  getFilteredCheckoutA4,
  add,
  addPos,
  logErrorPos,
  update,
  updateStatus,
  destroy,
  uploadCSV,
  downloadCSV,
  exportOutboundList,
  exportResultCSV,
  canGetInvoice,
  refundProducts,
  getOrderForRefund,
  returnOrder,
};
