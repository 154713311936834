import axios from "@/helpers/axios";
import authService from "@/store/services/auth-service";

const base_url = process.env.VUE_APP_API_BASE_URL;

function getDeliveryTypes(params) {
  return axios
    .get(`${base_url}delivery_types`, { params, headers: authService.authHeader() })
    .then((response) => {
      return response.data.delivery_types;
    });
}

export default {
  getDeliveryTypes,
};
