//import middleware
import auth from "@/middleware/auth";
import guest from "@/middleware/guest";
import Vue from "vue";

const routes = [
  // Login
  {
    name: "login",
    path: "/login",
    meta: { middleware: guest },
    component: () => import("@/views/auth/Login"),
  },
  {
    name: "forgot.password",
    path: "/forgot-password",
    meta: { middleware: guest },
    component: () => import("@/views/auth/ForgotPassword"),
  },
  {
    name: "reset.password",
    path: "/reset-password/:token",
    meta: { middleware: guest },
    component: () => import("@/views/auth/ResetPassword"),
  },
  {
    path: "/",
    component: () => import("@/views/dashboard/Index"),
    meta: { middleware: auth },
    children: [
      // Dashboard
      {
        name: "dashboard",
        path: "dashboard",
        meta: { middleware: auth },
        component: () => import("@/views/dashboard/rebrics/Index"),
      },

      ...(Vue.prototype.$admin.hasAccessTo("product")
        ? [
            {
              name: "ProductsByHub",
              path: "products-by-hub",
              meta: { middleware: auth },
              component: () => import("@/views/dashboard/rebrics/hubs/HubList"),
            },
          ]
        : []),

      ...(Vue.prototype.$admin.hasAccessTo("product")
        ? [
            {
              name: "setup.grouped_products.index",
              path: "setup/packs-and-bundles",
              meta: { middleware: auth },
              component: () =>
                Vue.prototype.$admin.can("packs-and-bundles-view") &&
                import("@/views/dashboard/rebrics/setup/PacksAndBundles.vue"),
            },
            {
              name: "setup.grouped_products.create",
              path: "setup/packs-and-bundles/create",
              meta: { middleware: auth },
              component: () =>
                Vue.prototype.$admin.can("packs-and-bundles-create") &&
                import(
                  "@/views/dashboard/rebrics/setup/packs-and-bundles/GroupedProductForm.vue"
                ),
            },
            {
              name: "setup.grouped_products.view",
              path: "setup/packs-and-bundles/:id/view",
              meta: { middleware: auth },
              component: () =>
                Vue.prototype.$admin.can("packs-and-bundles-view") &&
                import(
                  "@/views/dashboard/rebrics/setup/packs-and-bundles/View.vue"
                ),
            },
            {
              name: "setup.grouped_products.edit",
              path: "setup/packs-and-bundles/:id/edit",
              meta: { middleware: auth },
              component: () =>
                Vue.prototype.$admin.can("packs-and-bundles-update") &&
                import(
                  "@/views/dashboard/rebrics/setup/packs-and-bundles/GroupedProductForm.vue"
                ),
            },
          ]
        : []),

      ...(Vue.prototype.$admin.hasAccessTo("user")
        ? [
            {
              name: "users",
              path: "users",
              meta: { middleware: auth },
              component: () => import("@/views/dashboard/rebrics/Users"),
            },
          ]
        : []),

      ...(Vue.prototype.$admin.hasAccessTo("role")
        ? [
            {
              name: "roles",
              path: "roles",
              meta: { middleware: auth },
              component: () => import("@/views/dashboard/rebrics/Roles"),
            },
          ]
        : []),

      ...(Vue.prototype.$admin.hasAccessTo("order")
        ? [
            {
              name: "orders.all",
              path: "orders/all",
              meta: { middleware: auth },
              component: () =>
                import("@/views/dashboard/rebrics/orders/Orders"),
            },
            ...(Vue.prototype.$admin.hasAccessTo("log")
              ? [
                  {
                    name: "orders.log.sync",
                    path: "orders/log/sync",
                    meta: { middleware: auth },
                    component: () =>
                      import("@/views/dashboard/rebrics/orders/SyncLog"),
                  },
                  {
                    name: "orders.log.unsynced",
                    path: "orders/log/unsynced",
                    meta: { middleware: auth },
                    component: () =>
                      import("@/views/dashboard/rebrics/orders/UnsyncedOrder"),
                  },
                ]
              : []),
          ]
        : []),

      //New Pos
      ...(Vue.prototype.$admin.can("order-pos")
        ? [
            {
              name: "ops.local-orders",
              path: "pos-new/local-orders",
              meta: { middleware: auth },
              component: () =>
                import("@/views/dashboard/rebrics/pos/LocalOrders"),
            },
            {
              name: "ops-new.create",
              path: "pos-new/create",
              meta: { middleware: auth },
              component: () =>
                import("@/views/dashboard/rebrics/pos-new/Create"),
            },
            {
              name: "pos.history",
              path: "pos-new/history",
              meta: { middleware: auth },
              component: () =>
                import("@/views/dashboard/rebrics/pos-new/History"),
            },
          ]
        : []),
      //New Pos
      ...(Vue.prototype.$admin.hasAccessTo("checkout")
        ? [
            {
              name: "ops.checkouts",
              path: "pos-new/checkouts",
              meta: { middleware: auth },
              component: () =>
                import("@/views/dashboard/rebrics/pos/Checkouts"),
            },
          ]
        : []),

      ...(Vue.prototype.$admin.hasAccessTo("transfer")
        ? [
            {
              name: "transfers",
              path: "transfers",
              meta: { middleware: auth },
              component: () =>
                import("@/views/dashboard/rebrics/transfers/TransfersList"),
            },
          ]
        : []),

      ...(Vue.prototype.$admin.can("transfer-create")
        ? [
            {
              name: "transfers.create",
              path: "transfers/create",
              meta: { middleware: auth },
              component: () =>
                import("@/views/dashboard/rebrics/transfers/TransfersCreate"),
            },
            {
              name: "transfers.edit.expired",
              path: "transfers/:id/edit-expired",
              meta: { middleware: auth },
              component: () =>
                import(
                  "@/views/dashboard/rebrics/setup/alerts/transfer/TransferEditPage"
                ),
            },
            {
              name: "transfers.edit.draft",
              path: "transfers/:id/edit",
              meta: { middleware: auth },
              component: () =>
                import("@/views/dashboard/rebrics/transfers/TransfersCreate"),
            },
            {
              name: "transfers.edit.outgoing",
              path: "transfers/:id/edit-outgoing",
              meta: { middleware: auth },
              component: () =>
                import(
                  "@/views/dashboard/rebrics/transfers/TransfersUpdateOutgoing"
                ),
            },
          ]
        : []),

      ...(Vue.prototype.$admin.can("transfer-view")
        ? [
            {
              name: "transfers.view",
              path: "transfers/:id/view",
              meta: { middleware: auth },
              component: () =>
                import("@/views/dashboard/rebrics/transfers/TransfersShow"),
            },
            {
              name: "transfers.view.incoming",
              path: "transfers/:id/view-incoming",
              meta: { middleware: auth },
              component: () =>
                import(
                  "@/views/dashboard/rebrics/transfers/TransfersShowIncoming"
                ),
            },
          ]
        : []),

      ...(Vue.prototype.$admin.hasAccessTo("category")
        ? [
            {
              name: "shelving.shelves",
              path: "shelving/shelves",
              meta: { middleware: auth },
              component: () =>
                import("@/views/dashboard/rebrics/shelving/Shelves"),
            },
            {
              name: "shelving.categories",
              path: "shelving/categories",
              meta: { middleware: auth },
              component: () =>
                import("@/views/dashboard/rebrics/shelving/Categories"),
            },
            {
              name: "shelving.special-categories",
              path: "shelving/special-categories",
              meta: { middleware: auth },
              component: () =>
                import("@/views/dashboard/rebrics/shelving/SpecialCategories"),
            },
          ]
        : []),

      ...(Vue.prototype.$admin.hasAccessTo("brand")
        ? [
            {
              name: "setup.brands",
              path: "setup/brands",
              meta: { middleware: auth },
              component: () => import("@/views/dashboard/rebrics/setup/Brands"),
            },
          ]
        : []),

      ...(Vue.prototype.$admin.hasAccessTo("attribute")
        ? [
            {
              name: "setup.attributes",
              path: "setup/attributes",
              meta: { middleware: auth },
              component: () =>
                import("@/views/dashboard/rebrics/setup/Attributes"),
            },
          ]
        : []),

      ...(Vue.prototype.$admin.hasAccessTo("hub")
        ? [
            {
              name: "setup.hubs",
              path: "setup/hubs",
              meta: { middleware: auth },
              component: () => import("@/views/dashboard/rebrics/setup/Hubs"),
            },
          ]
        : []),

      ...(Vue.prototype.$admin.hasAccessTo("section")
        ? [
            {
              name: "setup.sections",
              path: "setup/sections",
              meta: { middleware: auth },
              component: () =>
                import("@/views/dashboard/rebrics/setup/Sections"),
            },
          ]
        : []),

      ...(Vue.prototype.$admin.hasAccessTo("provider")
        ? [
            {
              name: "setup.providers",
              path: "setup/providers",
              meta: { middleware: auth },
              component: () =>
                import("@/views/dashboard/rebrics/setup/Providers"),
            },
            {
              name: "setup.providers.edit",
              path: "setup/providers/:id/edit",
              meta: { middleware: auth },
              component: () =>
                import(
                  "@/views/dashboard/rebrics/setup/providers/providersCreate"
                ),
            },
            {
              name: "setup.providers.new",
              path: "setup/providers/new",
              meta: { middleware: auth },
              component: () =>
                import(
                  "@/views/dashboard/rebrics/setup/providers/providersCreate"
                ),
            },
          ]
        : []),
      ...(Vue.prototype.$admin.hasAccessTo("product")
        ? [
            {
              name: "setup.products",
              path: "setup/products",
              meta: { middleware: auth },
              component: () =>
                import("@/views/dashboard/rebrics/setup/products/Products"),
            },
            {
              name: "setup.products.create",
              path: "setup/products/create",
              meta: { middleware: auth },
              component: () =>
                Vue.prototype.$admin.can("product-create") &&
                import(
                  "@/views/dashboard/rebrics/setup/products/ProductsCreate"
                ),
            },
            {
              name: "setup.products.view",
              path: "setup/products/:id/view",
              meta: { middleware: auth },
              component: () =>
                Vue.prototype.$admin.can("product-view") &&
                import("@/views/dashboard/rebrics/setup/products/ProductsView"),
            },
            {
              name: "setup.products.edit",
              path: "setup/products/:id/edit",
              meta: { middleware: auth },
              component: () =>
                (Vue.prototype.$admin.can("product-update") ||
                  Vue.prototype.$admin.can("product-update-content")) &&
                import(
                  "@/views/dashboard/rebrics/setup/products/ProductsUpdate"
                ),
            },
          ]
        : []),

      ...(Vue.prototype.$admin.hasAccessTo("promotion")
        ? [
            {
              name: "promotions.index",
              path: "promotions",
              meta: { middleware: auth },
              component: () =>
                import(
                  "@/views/dashboard/rebrics/promotions/PromotionsList.vue"
                ),
            },
            {
              name: "promotions.create",
              path: "promotions/create",
              meta: { middleware: auth },
              component: () =>
                import(
                  "@/views/dashboard/rebrics/promotions/PromotionsCreate.vue"
                ),
            },
            {
              name: "promotions.edit",
              path: "promotions/:id/edit",
              meta: { middleware: auth },
              component: () =>
                import(
                  "@/views/dashboard/rebrics/promotions/PromotionsCreate.vue"
                ),
            },
            {
              name: "promotions.view",
              path: "promotions/:id/view",
              meta: { middleware: auth },
              component: () =>
                import(
                  "@/views/dashboard/rebrics/promotions/PromotionsView.vue"
                ),
            },
          ]
        : []),

      ...(Vue.prototype.$admin.hasAccessTo("coupon")
        ? [
            {
              name: "coupons.index",
              path: "coupons",
              meta: { middleware: auth },
              component: () =>
                import("@/views/dashboard/rebrics/coupons/CouponsList.vue"),
            },
            {
              name: "coupons.create",
              path: "coupons/create",
              meta: { middleware: auth },
              component: () =>
                import("@/views/dashboard/rebrics/coupons/CouponsCreate.vue"),
            },
            {
              name: "coupons.edit",
              path: "coupons/:id/edit",
              meta: { middleware: auth },
              component: () =>
                import("@/views/dashboard/rebrics/coupons/CouponsCreate.vue"),
            },
            {
              name: "coupons.view",
              path: "coupons/:id/view",
              meta: { middleware: auth },
              component: () =>
                import("@/views/dashboard/rebrics/coupons/CouponsView.vue"),
            },
          ]
        : []),

      ...(Vue.prototype.$admin.hasAccessTo("alert")
        ? [
            {
              name: "setup.alerts",
              path: "setup/alerts",
              meta: { middleware: auth },
              component: () => import("@/views/dashboard/rebrics/setup/Alerts"),
            },
          ]
        : []),

      ...(Vue.prototype.$admin.hasAccessTo("sync")
        ? [
            {
              name: "setup.sync.products",
              path: "setup/sync/products",
              meta: { middleware: auth },
              component: () =>
                import("@/views/dashboard/rebrics/setup/SyncProducts"),
            },
          ]
        : []),

      ...(Vue.prototype.$admin.hasAccessTo("inbound")
        ? [
            {
              name: "inbound.products",
              path: "inbound-products",
              meta: { middleware: auth },
              component: () =>
                import("@/views/dashboard/rebrics/inbounds/InboundList"),
            },
            {
              name: "inbound.products.edit",
              path: "inbound-products/:id/edit",
              meta: { middleware: auth },
              component: () =>
                import("@/views/dashboard/rebrics/inbounds/InboundUpdate"),
            },
            {
              name: "inbound.products.show",
              path: "inbound-products/:id/show",
              meta: { middleware: auth },
              component: () =>
                import("@/views/dashboard/rebrics/inbounds/InboundShow"),
            },
          ]
        : []),

      ...(Vue.prototype.$admin.hasAccessTo("supplyOrder")
        ? [
            {
              name: "supply.orders",
              path: "supply-orders",
              meta: { middleware: auth },
              component: () =>
                import(
                  "@/views/dashboard/rebrics/supply-orders/SupplyOrdersList"
                ),
            },
            {
              name: "supplyOrders.new",
              path: "supply-orders/new",
              meta: {},
              component: () =>
                import(
                  "@/views/dashboard/rebrics/supply-orders/SupplyOrdersCreate"
                ),
            },
            {
              name: "supplyOrders.view",
              path: "supply-orders/:id/view",
              meta: {},
              component: () =>
                import(
                  "@/views/dashboard/rebrics/supply-orders/SupplyOrdersShow"
                ),
            },
            {
              name: "supplyOrders.edit",
              path: "supply-orders/:id/edit",
              meta: { middleware: auth },
              component: () =>
                import(
                  "@/views/dashboard/rebrics/supply-orders/SupplyOrdersCreate"
                ),
            },
          ]
        : []),
      ...(Vue.prototype.$admin.hasAccessTo("stockManagement")
        ? [
            {
              name: "stock.managements",
              path: "stock-managements",
              meta: { middleware: auth },
              component: () =>
                import(
                  "@/views/dashboard/rebrics/stock-managements/StockManagementsList"
                ),
            },
            {
              name: "stock.managements.view",
              path: "stock-managements/:id/view",
              meta: { middleware: auth },
              component: () =>
                import(
                  "@/views/dashboard/rebrics/stock-managements/StockManagementsView"
                ),
            },
            {
              name: "stock.managements.view-batches",
              path: "stock-managements/:id/view-batches",
              meta: { middleware: auth },
              component: () =>
                import(
                  "@/views/dashboard/rebrics/stock-managements/StockManagementsViewBatches"
                ),
            },
          ]
        : []),
      ...(Vue.prototype.$admin.hasAccessTo("stockAdjustment")
        ? [
            {
              name: "stock.adjustments.view",
              path: "stock-adjustments/:id/view",
              meta: { middleware: auth },
              component: () =>
                import(
                  "@/views/dashboard/rebrics/stock-adjustments/StockAdjustmentsShow"
                ),
            },
            {
              name: "stock.adjustments",
              path: "stock-adjustments",
              meta: { middleware: auth },
              component: () =>
                import(
                  "@/views/dashboard/rebrics/stock-adjustments/StockAdjustmentsList"
                ),
            },
            {
              name: "stockAdjustments.new",
              path: "stock-adjustment/new",
              meta: {},
              component: () =>
                import(
                  "@/views/dashboard/rebrics/stock-adjustments/StockAdjustmentsCreate"
                ),
            },
            {
              name: "stockAdjustments.edit",
              path: "stock-adjustment/:id/edit",
              meta: { middleware: auth },
              component: () =>
                import(
                  "@/views/dashboard/rebrics/stock-adjustments/StockAdjustmentsCreate"
                ),
            },
          ]
        : []),

      ...(Vue.prototype.$admin.hasAccessTo("inventory")
        ? [
            {
              name: "inventories.index",
              path: "inventories/index",
              meta: { middleware: auth },
              component: () =>
                import("@/views/dashboard/rebrics/inventories/Index"),
            },
            {
              name: "inventories.create",
              path: "inventories/create",
              meta: { middleware: auth },
              component: () =>
                Vue.prototype.$admin.can("inventory-update") &&
                import("@/views/dashboard/rebrics/inventories/Create"),
            },
            {
              name: "inventories.edit",
              path: "inventories/:id/edit",
              meta: { middleware: auth },
              component: () =>
                Vue.prototype.$admin.can("inventory-update") &&
                import("@/views/dashboard/rebrics/inventories/Update"),
            },
            {
              name: "inventories.view",
              path: "inventories/:id/view",
              meta: { middleware: auth },
              component: () =>
                Vue.prototype.$admin.can("inventory-view") &&
                import("@/views/dashboard/rebrics/inventories/Show"),
            },
          ]
        : []),

      ...(Vue.prototype.$admin.hasAccessTo("accounting")
        ? [
            {
              name: "accounting",
              path: "accounting",
              meta: { middleware: auth },
              component: () => import("@/views/dashboard/rebrics/Accounting"),
            },
          ]
        : []),
      ...(Vue.prototype.$admin.hasAccessTo("statistic")
        ? [
            {
              name: "statistics",
              path: "statistics",
              meta: { middleware: auth },
              component: () => import("@/views/dashboard/rebrics/Statistics"),
            },
          ]
        : []),

      ...(Vue.prototype.$admin.can("setting-price")
        ? [
            {
              name: "settings.price",
              path: "settings/price",
              meta: { middleware: auth },
              component: () =>
                import("@/views/dashboard/rebrics/settings/PriceSettings"),
            },
          ]
        : []),

      {
        name: "settings.change-password",
        path: "settings/change-password",
        meta: { middleware: auth },
        component: () =>
          import("@/views/dashboard/rebrics/settings/ChangePassword"),
      },
      {
        name: "settings.procurement-settings",
        path: "settings/procurement-settings",
        meta: { middleware: auth },
        component: () =>
          import("@/views/dashboard/rebrics/settings/ProcurementSettings"),
      },
      {
        name: "settings.category-settings",
        path: "settings/category-settings",
        meta: { middleware: auth },
        component: () =>
          import("@/views/dashboard/rebrics/settings/CategorySettings"),
      },
    ],
  },
];

export default routes;
