import service from "@/store/services/badge-service";

const state = {
  isLoadingBadges: false,
  listBadges: [],
  productsStockAlert: {},
  productsExpiryAlert: {},
};

const mutations = {
  SET_BADGES: (state, badges) => {
    state.listBadges = badges;
  },

  SET_PRODUCTS_STOCK_ALERT: (state, badge) => {
    state.productsStockAlert = badge;
  },
  SET_PRODUCTS_EXPIRY_ALERT: (state, badge) => {
    state.productsExpiryAlert = badge;
  },
};

const actions = {
  list: (store) => {
    store.state.isLoadingBadges = true;
    return service.list().then((badges) => {
      store.commit("SET_BADGES", badges);
      store.state.isLoadingBadges = false;
    });
  },

  getProductsAlert: (store, type) => {
    store.state.isLoadingBadges = true;
    return service.getProductsAlert(type).then((badge) => {
      if (type == "stock") {
        store.commit("SET_PRODUCTS_STOCK_ALERT", badge);
      } else if (type == "expiry") {
        store.commit("SET_PRODUCTS_EXPIRY_ALERT", badge);
      }
      store.state.isLoadingBadges = false;
    });
  },
};

const getters = {
  listBadges(state) {
    return state.listBadges;
  },

  getProductsStockAlert(state) {
    return state.productsStockAlert;
  },
  getProductsExpiryAlert(state) {
    return state.productsExpiryAlert;
  },
};

const badges = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default badges;
