import axios from "@/helpers/axios";
import authService from "@/store/services/auth-service";

const base_url = process.env.VUE_APP_API_BASE_URL;

async function show(options) {
  const res = await axios.get(`${base_url}checkouts/${options.id}`, {
    headers: authService.authHeader(),
  });
  return res.data.checkout;
}

async function getPDF_A4(checkout) {
  const res = await axios.get(`${base_url}get_checkout_pdf_a4/${checkout.id}`, {
    headers: authService.authHeader(),
    responseType: "blob",
  });
  return res.data;
}

function list(option) {
  return axios
    .get(`${base_url}checkouts`, {
      params: option,
      headers: authService.authHeader(),
    })
    .then((response) => {
      return response.data;
    });
}

export default {
  list,
  show,
  getPDF_A4,
};
