import axios from "@/helpers/axios";
import authService from "@/store/services/auth-service";

const base_url = process.env.VUE_APP_API_BASE_URL;

async function list(params) {
  const res = await axios.get(`${base_url}hubs`, {
    params,
    headers: authService.authHeader(),
  });
  return res.data;
}
async function types() {
  const res = await axios.get(`${base_url}hubs-types`, {
    headers: authService.authHeader(),
  });
  return res.data;
}
async function add(hub) {
  const res = await axios.post(`${base_url}hubs`, hub, {
    headers: authService.authHeader(),
  });
  return res.data.hub;
}

async function update(hub) {
  const res = await axios.put(`${base_url}hubs/${hub.id}`, hub, {
    headers: authService.authHeader(),
  });
  return res.data.hub;
}

async function destroy(hub) {
  return await axios.delete_method(`${base_url}hubs/${hub.id}`, {
    headers: authService.authHeader(),
  });
}

async function generateJWT(hub) {
  const res = await axios.get(`${base_url}sync/hubs/generate-token/${hub.id}`, {
    headers: authService.authHeader(),
  });
  return res.data.token;
}

async function updateExternalJWT(hub) {
  const res = await axios.post(
    `${base_url}sync/hubs/update-external-jwt`,
    hub,
    { headers: authService.authHeader() }
  );
  return res.data.token;
}

export default {
  list,
  types,
  add,
  update,
  destroy,
  generateJWT,
  updateExternalJWT,
};
