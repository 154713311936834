import axios from "@/helpers/axios";
import authService from "@/store/services/auth-service";

const base_url = process.env.VUE_APP_API_BASE_URL;

function syncWithExpress(params) {
  return axios
    .post(`${base_url}products/sync-with-express`, params, {
      headers: authService.authHeader(),
    })
    .then((response) => {
      return response.data;
    });
}

export default {
  syncWithExpress,
};
