import Vue from "vue";
import service from "@/store/services/stock-adjustment-service";

const state = {
  isLoadingStockAdjustments: false,
  isLoadingStockAdjustment: false,

  editedStockAdjustment: {},
  stockAdjustment: {},

  list: [],

  meta: {},
  params: {},
  productsList: [],
  filters: {},
  selected: 0,
};

const mutations = {
  EDITED_STOCK_ADJUSTMENT(state, stockAdjustment) {
    Vue.set(state, "editedStockAdjustment", stockAdjustment);
    Vue.set(state, "stockAdjustment", stockAdjustment);
  },

  STOCKADJUSTMENT(state, stockAdjustment) {
    Vue.set(state, "stockAdjustment", stockAdjustment);
  },

  CLEAN_PRODUCTS_LIST(state) {
    Vue.set(state, "productsList", []);
  },
  SET_LIST(state, products) {
    Vue.set(state, "list", products);
  },
  SET_STOCK_ADJUSTMENT(state, stockAdjustments) {
    state.list = stockAdjustments;
  },

  ADD_STOCK_ADJUSTMENT(state, stockAdjustment) {
    state.list.push(stockAdjustment);
  },

  SET_META(state, data) {
    state.meta.page = data.meta.current_page;
    state.meta.itemsPerPage = data.meta.per_page;
    state.meta.lastPage = data.meta.last_page;
    state.meta.totalItems = data.meta.total;
    state.meta.manual_cancellation = data.meta.manual_cancellation;
  },

  SET_PARAMS(state, payload) {
    Vue.set(state, "params", payload);
  },

  SET_FILTERS(state, filters) {
    Vue.set(state.supplyOrder, "filters", filters);
  },

  SET_SELECTED(state, selected) {
    state.selected = selected;
  },

  CLEAN_LIST(state) {
    Vue.set(state, "list", []);
  },

  DELETE_STOCK_ADJUSTMENT(state, stockAdjustment) {
    state.list.splice(
      state.list.map((item) => item.id).indexOf(stockAdjustment.id),
      1
    );
  },
  
  SET_IMPORT_StockAdjustment_MODAL_VISIBLE(state, visible) {
    Vue.set(state, "isImportStockAdjustmentModalVisible", visible);
  },
  SET_IMPORT_ERROR_FILE(state, file) {
    Vue.set(state, "importErrorFile", file);
  },
  SET_IMPORT_STATUS(state, status) {
    Vue.set(state, "importStatus", status);
  },
};

const actions = {
  add: (store, stockAdjustment) => {
    return service.add(stockAdjustment).then((item) => {
      store.commit("ADD_STOCK_ADJUSTMENT", item);
    });
  },

  addProductWithLot: (store, params) => {
    return service.addProductWithLot(params).then((stockAdjustment) => {
      store.commit("STOCKADJUSTMENT", stockAdjustment);
    });
  },
  removeFromCart: (store, params) => {
    return service.removeFromCart(params).then((stockAdjustment) => {
      store.commit("STOCKADJUSTMENT", stockAdjustment);
    });
  },

  setParams: (store, params) => {
    store.commit("SET_PARAMS", params);
  },

  list: (store, params) => {
    store.state.isLoadingStockAdjustments = true;
    return service
      .list(params)
      .then((data) => {
        store.commit("SET_STOCK_ADJUSTMENT", data.stockAdjustments);
        store.commit("SET_META", data);
        store.state.isLoadingStockAdjustments = false;
      })
      .catch((error) => {
        store.state.isLoadingStockAdjustments = false;
        throw error;
      });
  },
  destroy: (store, stockAdjustment) => {
    return service.destroy(stockAdjustment).then(() => {
      store.commit("DELETE_STOCK_ADJUSTMENT", stockAdjustment);
    });
  },

  update_status: (store, stockAdjustment) => {
    return service.update_status(stockAdjustment).then(() => {
      store.commit("EDITED_STOCK_ADJUSTMENT", stockAdjustment);
    });
  },


  update: (store, stockAdjustment) => {
    return service.update(stockAdjustment).then((item) => {
      store.commit("EDITED_STOCK_ADJUSTMENT", item);
    });
  },

  toggleImportStockAdjustmentModal({ commit, state }) {
    commit(
      "SET_IMPORT_StockAdjustment_MODAL_VISIBLE",
      !state.isImportStockAdjustmentModalVisible
      
    );
  },
  import: async (store, file) => {
    store.commit("SET_IMPORT_STATUS", "uploading");
    store.commit("SET_IMPORT_ERROR_FILE", null);
    return await service.uploadCSVStockAdjustment(file);
  },
  downloadTemplate: () => {
    return service.downloadTemplate();
  },
  downloadFailedResult: ({ state }) => {
    return service.downloadFailedResult(state.importErrorFile);
  },
  downloadCSV: (store, option) => {
    return service.downloadCSV(option);
  },
  downloadCSVResult: (store, option) => {
    return service.downloadCSVResult(option);
  },
};

const getters = {
  isLoadingStockAdjustment(state) {
    return state.isLoadingStockAdjustment;
  },

  isLoadingStockAdjustments(state) {
    return state.isLoadingStockAdjustments;
  },

  editedStockAdjustment(state) {
    return state.editedStockAdjustment;
  },

  stockAdjustment(state) {
    return state.stockAdjustment;
  },

  productsList(state) {
    return state.productsList;
  },

  meta(state) {
    return state.meta;
  },
  params(state) {
    return state.params;
  },
  filters(state) {
    return state.filters;
  },
  selected(state) {
    return state.selected;
  },

  list(state) {
    return state.list;
  },
};

const stockAdjustment = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default stockAdjustment;
