import Vue from "vue";
import service from "@/store/services/order-service";
import i18n from "@/i18n";

const state = {
  isVisibleDialog: false,
  isVisibleDeleteDialog: false,
  isVisibleShow: false,
  isLoadingOrders: false,
  isLoadingOrder: false,
  isLoadingFile: false,
  hubID: null,

  order: { products: [] },
  refundOrder: { products: [] },

  editedOrder: {
    reduction: 0,
    type: "default",
  },
  listOrders: [],
  trackingNumbers: [],
  meta: {},

  isLoadingTypes: false,
  types: [],

  isSendingAddPos: false,
};

const mutations = {
  IS_VISIBLE_DIALOG(state, is_visible) {
    Vue.set(state, "isVisibleDialog", is_visible);
  },

  IS_VISIBLE_DELETE_DIALOG(state, is_visible) {
    Vue.set(state, "isVisibleDeleteDialog", is_visible);
  },

  IS_VISIBLE_SHOW(state, is_visible) {
    Vue.set(state, "isVisibleShow", is_visible);
  },

  EDITED_ORDER(state, order) {
    Vue.set(state, "editedOrder", order);
  },

  ORDER(state, order) {
    Vue.set(state, "order", order);
  },

  EDITED_PRODUCTS(state, products) {
    Vue.set(state.editedOrder, "products", products);
    // state.editedOrder.products = products
  },
  ORDER_ITEMS(state, products) {
    Vue.set(state.editedOrder, "orderItems", products);
  },

  SET_HUB_ID(state, hub_id) {
    state.hubID = hub_id;
  },

  SET_ORDERS(state, orders) {
    state.listOrders = orders;
  },

  SET_TRACKING_NUMBERS(state, trackingNumbers) {
    state.trackingNumbers = trackingNumbers;
  },

  SET_TYPES(state, order_types) {
    state.types = order_types;
  },

  ADD_ORDER(state, order) {
    state.listOrders.push(order);
  },

  CLEAN_LIST(state) {
    Vue.set(state, "listOrders", []);
  },

  UPDATE_ORDER(state, order) {
    var item = state.listOrders.find((item) => item.id === order.id);
    Object.assign(item, order);
  },

  DELETE_ORDER(state, order) {
    state.listOrders.splice(
      state.listOrders.map((item) => item.id).indexOf(order.id),
      1
    );
  },

  SET_META(state, data) {
    state.meta.page = data.meta.current_page;
    state.meta.itemsPerPage = data.meta.per_page;
    state.meta.lastPage = data.meta.last_page;
    state.meta.totalItems = data.meta.total;
    // Vue.set(state, "listProducts", products);
  },

  IS_SENDING_ADD_POS(state, is_sending_add_pos) {
    Vue.set(state, "isSendingAddPos", is_sending_add_pos);
  },

  SET_REFUND_ORDER(state, order) {
    Vue.set(state, "refundOrder", order);
  },
};

const actions = {
  openEditForm: async (store, order) => {
    await service.show({ id: order.id }).then((order) => {
      store.commit("IS_VISIBLE_DIALOG", true);
      store.commit("EDITED_ORDER", Object.assign({}, order));
    });
  },

  openDeleteForm: (store, order) => {
    store.commit("IS_VISIBLE_DELETE_DIALOG", true);
    store.commit("EDITED_ORDER", Object.assign({}, order));
  },

  openShowForm: async (store, order) => {
    await service.show({ id: order.id }).then((order) => {
      store.commit("IS_VISIBLE_SHOW", true);
      store.commit("ORDER", Object.assign({}, order));
    });
  },

  getOrder: async (store, orderId) => {
    store.state.isLoadingOrders = true;
    const order = orderId
      ? await service.show({ id: orderId })
      : { products: [] };
    store.commit("ORDER", Object.assign({}, order));
    store.state.isLoadingOrders = false;
  },

  getRefundOrder: async (store, orderId) => {
    store.state.isLoadingOrders = true;
    const order = orderId
      ? await service.getOrderForRefund({ id: orderId })
      : { products: [] };
    store.commit("SET_REFUND_ORDER", Object.assign({}, order));
    store.state.isLoadingOrders = false;
  },

  closeForm: (store) => {
    store.commit("IS_VISIBLE_DIALOG", false);
    store.commit("IS_VISIBLE_DELETE_DIALOG", false);
    store.commit("IS_VISIBLE_SHOW", false);
    store.commit("EDITED_ORDER", {});
    store.commit("ORDER", {});
  },

  list: (store, params) => {
    store.state.isLoadingOrders = true;
    return service.list(params).then((data) => {
      store.commit("SET_ORDERS", data.orders);
      store.commit("SET_META", data);
      store.state.isLoadingOrders = false;
    });
  },
  autoComplete: async (store, search) => {
    store.state.isLoadingOrders = true;
    try {
      const { orders } = await service.autoComplete(search);
      store.commit("SET_TRACKING_NUMBERS", orders);
    } finally {
      store.state.isLoadingOrders = false;
    }
  },
  types: (store) => {
    store.state.isLoadingTypes = true;
    return service
      .types()
      .then((data) => {
        store.commit("SET_TYPES", data.order_types);
        store.state.isLoadingTypes = false;
      })
      .catch((error) => {
        store.state.isLoadingTypes = false;
        throw error;
      });
  },

  getInvoice: (store, order) => {
    store.state.isLoadingFile = true;
    return service.getInvoice(order).then(function (response) {
      store.state.isLoadingFile = false;
      return response;
    });
  },

  getDeliveryNote: (store, order) => {
    store.state.isLoadingFile = true;
    return service.getDeliveryNote(order).then(function (response) {
      store.state.isLoadingFile = false;
      return response;
    });
  },

  getPosInvoice: (store, order) => {
    store.state.isLoadingFile = true;
    return service.getPosInvoice(order).then(function (response) {
      store.state.isLoadingFile = false;
      return response;
    });
  },

  getPosCheckoutA7: (store) => {
    store.state.isLoadingFile = true;
    return service.getPosCheckoutA7().then(function (response) {
      store.state.isLoadingFile = false;
      return response;
    });
  },

  getFilteredCheckoutA4: (store, filter) => {
    store.state.isLoadingFile = true;
    return service.getFilteredCheckoutA4(filter).then(function (response) {
      store.state.isLoadingFile = false;
      return response;
    });
  },

  add: (store, order) => {
    return service.add(order).then((item) => {
      store.commit("ADD_ORDER", item);
      // store.dispatch('list',{page:1,itemsPerPage:30,order_status_type:'unsettled'})
    });
  },
  addPos: (store, order) => {
    return service.addPos(order).then((item) => {
      store.commit("ADD_ORDER", item);
      store.commit("ORDER", item);
      return item;
    });
  },

  logErrorPos: (store, order) => {
    return service.logErrorPos(order);
  },

  update: (store, order) => {
    return service.update(order).then((item) => {
      store.commit("UPDATE_ORDER", item);
    });
  },

  updateStatus: (store, params) => {
    return service.updateStatus(params).then((item) => {
      store.commit("UPDATE_ORDER", item);
    });
  },

  destroy: (store) => {
    if (store.state.editedOrder) {
      const order = store.state.editedOrder;
      return service.destroy(order).then(() => {
        store.commit("DELETE_ORDER", order);
      });
    }
  },

  uploadCSV: (store, payload) => {
    return service.uploadCSV(payload).then((data) => {
      store.commit("SET_PRODUCTS", data.products);
      store.commit("SET_META", data);
    });
  },

  downloadCSV: (store, option) => {
    return service.downloadCSV(option);
  },

  refundProducts: async (store, order) => {
    await service.refundProducts(order);
    return true;
  },

  returnOrder: async (store, orderId) => {
    await service.returnOrder(orderId);
    return true;
  },
};

const getters = {
  isVisibleDialog(state) {
    return state.isVisibleDialog;
  },

  isLoadingOrders(state) {
    return state.isLoadingOrders;
  },

  isLoadingOrder(state) {
    return state.isLoadingOrder;
  },

  editedIndex(state) {
    return state.editedIndex;
  },

  editedOrder(state) {
    return state.editedOrder;
  },

  getHubID(state) {
    return state.hubID;
  },

  order(state) {
    return state.order;
  },

  refundOrder(state) {
    return state.refundOrder;
  },

  listOrders(state) {
    return state.listOrders;
  },

  trackingNumbers(state) {
    return state.trackingNumbers;
  },

  getTypes(state) {
    return state.types.map(function (item) {
      return { name: i18n.t(item), value: item };
    });
  },

  readyForTransferOrders(state) {
    return state.listOrders.filter(
      (item) => item.orderStatus.name == "ready for transfer"
    );
  },

  meta(state) {
    return state.meta;
  },

  isSendingAddPos(state) {
    return state.isSendingAddPos;
  },
};

const orders = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default orders;
