import service from "@/store/services/permission-service";

const state = {
  isLoadingPermissions: false,
  listPermissions: [],
  meta: {},
};

const mutations = {
  SET_PERMISSIONS(state, list_permissions) {
    state.listPermissions = list_permissions;
  },

  SET_META(state, data) {
    if (data.meta) {
      state.meta.page = data.meta.current_page;
      state.meta.itemsPerPage = data.meta.per_page;
      state.meta.lastPage = data.meta.last_page;
      state.meta.totalItems = data.meta.total;
    }
  },
};

const actions = {
  list: (store, params) => {
    store.state.isLoadingPermissions = true;
    service.list(params).then((data) => {
      store.commit("SET_PERMISSIONS", data.permissions);
      store.commit("SET_META", data);
      store.state.isLoadingPermissions = false;
    });
  },
};

const getters = {
  isLoadingPermissions(state) {
    return state.isLoadingPermissions;
  },

  listPermissions(state) {
    return state.listPermissions;
  },

  meta(state) {
    return state.meta;
  },
};

const permissions = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default permissions;
