import axios from "@/helpers/axios";
import authService from "@/store/services/auth-service";

const base_url = process.env.VUE_APP_API_BASE_URL;

function list(params) {
  return axios
    .get(`${base_url}categories`, { params, headers: authService.authHeader() })
    .then((response) => {
      return response.data.categories;
    });
}
function show(params) {
  return axios
    .get(`${base_url}categories/${params.id}`, {
      params,
      headers: authService.authHeader(),
    })
    .then((response) => {
      return response.data.category;
    });
}

function add(category) {
  return axios
    .post(`${base_url}categories`, category, {
      headers: {
        "Content-Type": "multipart/form-data",
        ...authService.authHeader(),
      },
    })
    .then((response) => {
      return response.data.category;
    });
}

function update(category) {
  return axios
    .post(`${base_url}categories/update/${category.id}`, category, {
      headers: {
        "Content-Type": "multipart/form-data",
        ...authService.authHeader(),
      },
    })
    .then((response) => {
      return response.data.category;
    });
}

function destroy(category) {
  return axios
    .delete_method(`${base_url}categories/${category.id}`, {
      headers: authService.authHeader(),
    })
    .then((response) => {
      return response.data.category;
    });
}

function destroyUnused() {
  return axios.post(
    `${base_url}categories/delete-unused`,
    {},
    { headers: authService.authHeader() }
  );
}

async function uploadCSV(csv) {
  const res = await axios.post(`${base_url}categories/upload_csv`, csv, {
    headers: {
      "Content-Type": "multipart/form-data",
      ...authService.authHeader(),
    },
  });
  return res.data;
}

async function exportCSV(option) {
  const res = await axios.get(`${base_url}category_export_csv`, {
    params: option,
    headers: authService.authHeader(),
    responseType: "blob",
  });
  return res.data;
}

async function getSpecialCategorySetting() {
  const res = await axios.get(`${base_url}special-category-settings`, {
    headers: authService.authHeader(),
  });
  return res.data;
}

async function updateSpecialCategorySettings(params) {
  const { automatic_enable_sub_category } = params;
  const res = await axios.put(
    `${base_url}special-category-settings`,
    {
      automatic_enable_sub_category,
    },
    {
      headers: authService.authHeader(),
    }
  );
  return res.data;
}
async function getCategorySetting() {
  const res = await axios.get(`${base_url}category-settings`, {
    headers: authService.authHeader(),
  });
  return res.data;
}

async function updateCategorySettings(params) {
  const { automatic_enable_sub_category } = params;
  const res = await axios.put(
    `${base_url}category-settings`,
    {
      automatic_enable_sub_category,
    },
    {
      headers: authService.authHeader(),
    }
  );
  return res.data;
}
export default {
  list,
  show,
  add,
  update,
  destroy,
  destroyUnused,
  uploadCSV,
  exportCSV,
  getSpecialCategorySetting,
  updateSpecialCategorySettings,
  getCategorySetting,
  updateCategorySettings,
};
