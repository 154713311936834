import axios from "@/helpers/axios";
import authService from "@/store/services/auth-service";

const base_url = process.env.VUE_APP_API_BASE_URL;

async function list(params) {
  const res = await axios.get(`${base_url}inbounds`, {
    params: params,
    headers: authService.authHeader(),
  });
  return res.data;
}

async function show(options) {
  const res = await axios.get(`${base_url}inbounds/${options.id}`, {
    headers: authService.authHeader(),
  });
  return res.data.inbound;
}

async function getInvoice(inbound) {
  const res = await axios.get(
    `${base_url}generate_invoice_inbound_a4/${inbound.id}`,
    { headers: authService.authHeader(), responseType: "blob" }
  );
  return res.data;
}

async function add(inbound) {
  const res = await axios.post(`${base_url}inbounds`, inbound, {
    headers: authService.authHeader(),
  });
  return res.data.inbound;
}

async function update(inbound) {
  const res = await axios.put(
    `${base_url}inbounds/${inbound.id}/update-incoming`,
    inbound,
    {
      headers: authService.authHeader(),
    }
  );
  return res.data.inbound;
}
async function updateProductPrices(data) {
  const res = await axios.put(
    `${base_url}update_product_prices/${data.inbound_id}/product/${data.product_id}`,
    data,
    {
      headers: authService.authHeader(),
    }
  );
  return res.data.inbound;
}
async function confirm(inbound) {
  const res = await axios.put(
    `${base_url}validate_inbound/${inbound.id}`,
    inbound,
    { headers: authService.authHeader() }
  );
  return res.data.inbound;
}
async function reopen(inbound) {
  const res = await axios.put(
    `${base_url}reopen_inbound/${inbound.id}`,
    {},
    { headers: authService.authHeader() }
  );
  return res.data.inbound;
}

function destroy(inbound) {
  return axios.delete_method(`${base_url}inbounds/${inbound.id}`, {
    headers: authService.authHeader(),
  });
}

async function uploadCSV(csv) {
  const res = await axios.post(`${base_url}inbounds/upload_csv`, csv, {
    headers: {
      "Content-Type": "multipart/form-data",
      ...authService.authHeader(),
    },
  });
  return res.data;
}

async function downloadCSV(option) {
  const res = await axios.get(`${base_url}inbounds_export_csv`, {
    params: option,
    headers: authService.authHeader(),
    responseType: "blob",
  });
  return res.data;
}
async function downloadCSVResult(option) {
  const res = await axios.get(`${base_url}inbounds_export_result_csv`, {
    params: option,
    headers: authService.authHeader(),
    responseType: "blob",
  });
  return res.data;
}

export default {
  list,
  show,
  add,
  update,
  destroy,
  confirm,
  reopen,
  getInvoice,
  uploadCSV,
  downloadCSV,
  updateProductPrices,
  downloadCSVResult,
};
