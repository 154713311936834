import service from "@/store/services/order-status-service";

const state = {
  isLoadingStatuses: false,
  listStatuses: [],
  nextStatusesIds: {
    pending: [2, 11],

    "ready for shipping": [6, 10, 11],
    "ready for transfer": [],

    transferred: [],
    "in transfer": [],
    shipped: [7, 10],

    delivered: [8, 9],
    returned: [],
    "partially returned": [],
    canceled: [1],
    archived: [],

    "first order": [6, 10, 11],
    "new order": [6, 10, 11],
    "ds accepted": [6, 10, 11],
    "price check": [6, 10, 11],
    "quantities check": [6, 10, 11],
    "epayment pending": [6, 10, 11],
  },
};

const mutations = {
  SET_STATUSES: (state, statuses) => {
    state.listStatuses = statuses;
  },
};

const actions = {
  list: (store) => {
    store.state.isLoadingStatuses = true;
    return service.list().then((statuses) => {
      store.commit("SET_STATUSES", statuses);
      store.state.isLoadingStatuses = false;
    });
  },
};

const getters = {
  isLoadingStatuses(state) {
    return state.isLoadingStatuses;
  },

  listStatuses(state) {
    return state.listStatuses;
  },
  nextStatusesIds(state) {
    return state.nextStatusesIds;
  },
};

const orderStatuses = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default orderStatuses;
