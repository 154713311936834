const state = {
  listStatuses: ["active", "inactive", "new"],
};

const mutations = {
  SET_STATUSES: (state) => {
    state.listStatuses = ["active", "inactive", "new"];
  },
};

const actions = {
  list(store) {
    store.commit("SET_STATUSES");
  },
};

const getters = {
  listStatuses(state) {
    return state.listStatuses;
  },
};

const statuses = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default statuses;
