import Vue from "vue";
import { GroupedProduct } from "@/classes/packAndBundle.class";

const state = {
  isVisibleEditPackageItemDialog: false,
  isVisibleEditBundleItemsDialog: false,
  editedPackItem: new GroupedProduct(),
  editedBundleItems: Array(),
  selectedCategory: null,
};

const mutations = {
  IS_VISIBLE_EDIT_PACKAGE_ITEM_DIALOG(state, is_visible) {
    Vue.set(state, "isVisibleEditPackageItemDialog", is_visible);
  },
  IS_VISIBLE_EDIT_BUNDLE_ITEMS_DIALOG(state, is_visible) {
    Vue.set(state, "isVisibleEditBundleItemsDialog", is_visible);
  },
  SET_EDITED_PACK_ITEM(state, item) {
    Vue.set(state, "editedPackItem", item);
  },
  SET_EDITED_BUNDLE_ITEMS(state, items) {
    Vue.set(state, "editedBundleItems", items);
  },
  SET_SELECTED_CATEGORY(state, category) {
    Vue.set(state, "selectedCategory", category);
  },
};

const actions = {
  openEditPackageItemForm: (store) => {
    store.commit("IS_VISIBLE_EDIT_PACKAGE_ITEM_DIALOG", true);
  },
  openEditBundlesItemsForm: (store) => {
    store.commit("IS_VISIBLE_EDIT_BUNDLE_ITEMS_DIALOG", true);
  },
  closeForm: (store) => {
    store.commit("IS_VISIBLE_EDIT_BUNDLE_ITEMS_DIALOG", false);
    store.commit("IS_VISIBLE_EDIT_PACKAGE_ITEM_DIALOG", false);
  },
  selectPackItem(store, product) {
    store.commit("SET_EDITED_PACK_ITEM", product);
    store.commit("IS_VISIBLE_EDIT_PACKAGE_ITEM_DIALOG", false);
  },
  addBundleItems(store, products) {
    store.commit("SET_EDITED_BUNDLE_ITEMS", products);
    store.commit("IS_VISIBLE_EDIT_BUNDLE_ITEMS_DIALOG", false);
  },
  resetItems(store) {
    store.commit("SET_EDITED_PACK_ITEM", {});
    store.commit("SET_EDITED_BUNDLE_ITEMS", []);
  },
  setSelectedCategory(store, category) {
    store.commit("SET_SELECTED_CATEGORY", category);
  },
};

const getters = {
  editedPackItem(state) {
    return state.editedPackItem;
  },
  editedBundleItems(state) {
    return state.editedBundleItems;
  },
  selectedCategory(state) {
    return state.selectedCategory;
  },
};

const PacksAndBundles = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default PacksAndBundles;
