import Vue from "vue";
import service from "@/store/services/provider-service";

const state = {
  isVisibleDialog: false,
  isVisibleDeleteDialog: false,
  isLoadingProviders: false,
  isLoadingProvider: false,
  editedProvider: {},
  provider: {},
  listProviders: [],
  meta: {},
};

const mutations = {
  IS_VISIBLE_DIALOG(state, is_visible) {
    Vue.set(state, "isVisibleDialog", is_visible);
  },
  IS_VISIBLE_DELETE_DIALOG(state, is_visible) {
    Vue.set(state, "isVisibleDeleteDialog", is_visible);
  },
  EDITED_PROVIDER(state, provider) {
    Vue.set(state, "editedProvider", provider);
  },

  SET_PROVIDERS(state, providers) {
    state.listProviders = providers;
  },
  ADD_PROVIDER(state, provider) {
    state.listProviders.push(Object.assign({}, provider));
  },

  UPDATE_PROVIDER(state, provider) {
    const item = state.listProviders.find((item) => item.id === provider.id);
    if (item) {
      Object.assign(item, provider);
    }
  },

  DELETE_PROVIDER(state, provider) {
    state.listProviders.splice(
      state.listProviders.map((o) => o.id).indexOf(provider.id),
      1
    );
  },

  SET_META(state, data) {
    if (data.meta) {
      state.meta.page = data.meta.current_page;
      state.meta.itemsPerPage = data.meta.per_page;
      state.meta.lastPage = data.meta.last_page;
      state.meta.totalItems = data.meta.total;
    }
  },
};

const actions = {
  loadProvider: async (store, providerId) => {
    store.state.isLoadingProvider = true;
    await service.show({ id: providerId }).then((provider) => {
      provider.status = provider.status === "active";
      store.commit("EDITED_PROVIDER", provider);
      store.state.isLoadingProvider = false;
    });
  },

  openEditForm: (store, provider) => {
    store.commit("EDITED_PROVIDER", Object.assign({}, provider));
  },

  openDeleteForm: (store, provider) => {
    store.commit("IS_VISIBLE_DELETE_DIALOG", true);
    store.commit("EDITED_PROVIDER", Object.assign({}, provider));
  },

  closeForm: (store) => {
    store.commit("IS_VISIBLE_DIALOG", false);
    store.commit("IS_VISIBLE_DELETE_DIALOG", false);
    store.commit("EDITED_PROVIDER", {});
  },

  list: (store, params) => {
    store.state.isLoadingProviders = true;
    return service.list(params).then((data) => {
      store.commit("SET_PROVIDERS", data.providers);
      store.commit("SET_META", data);
      store.state.isLoadingProviders = false;
    });
  },

  add: (store, provider) => {
    return service.add(provider).then((provider) => {
      store.commit("ADD_PROVIDER", provider);
      store.dispatch("list", { page: 1, itemsPerPage: 30 });
    });
  },

  update: (store, provider) => {
    return service.update(provider).then((provider) => {
      store.commit("UPDATE_PROVIDER", provider);
    });
  },

  destroy: (store) => {
    if (store.state.editedProvider) {
      const provider = store.state.editedProvider;
      return service.destroy(provider).then(() => {
        store.commit("DELETE_PROVIDER", provider);
      });
    }
  },

  toggleStatus: (store, provider) => {
    store.state.isLoadingProviders = true;
    service.toggleStatus(provider).then((provider) => {
      store.commit("UPDATE_PROVIDER", provider);
      store.state.isLoadingProviders = false;
    });
  },

  downloadCSV: (store, option) => {
    return service.downloadCSV(option);
  },
};

const getters = {
  isVisibleDialog(state) {
    return state.isVisibleDialog;
  },

  isLoadingProviders(state) {
    return state.isLoadingProviders;
  },

  editedProvider(state) {
    return state.editedProvider;
  },

  listProviders(state) {
    return state.listProviders;
  },

  activeProviders(state) {
    return state.listProviders.filter((item) => {
      return item.status == "active";
    });
  },
  meta(state) {
    return state.meta;
  },
};

const providers = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default providers;
