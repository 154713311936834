import Vue from "vue";
import service from "@/store/services/promotions-service";
import Promotion from "@/classes/promotion.class.js";

const state = {
  list: [],
  isListLoading: false,
  promotionDetails: null,
  isPromotionDetailsLoading: false,
  promotion: new Promotion(),
  productsList: [],
  isProductsListLoading: false,
  meta: {},
  isImportPromotionsModalVisible: false,
  importErrorFile: null,
  importStatus: null,
};

const mutations = {
  INITIALIZE() {
    Object.assign(state, {
      ...state,
      list: [],
      isListLoading: false,
      promotionDetails: null,
      isPromotionDetailsLoading: false,
      promotion: new Promotion(),
      productsList: [],
      isProductsListLoading: false,
      meta: {},
    });
  },
  CLEAR_LIST(state) {
    Vue.set(state, "list", []);
  },
  CLEAR_PROMOTION_DETAILS(state) {
    Vue.set(state, "promotionDetails", []);
  },
  CLEAN_PRODUCTS_LIST(state) {
    Vue.set(state, "productsList", []);
  },
  SET_LIST(state, products) {
    Vue.set(state, "list", products);
  },
  SET_PROMOTION_DETAILS(state, promotionDetails) {
    Vue.set(state, "promotionDetails", promotionDetails);
  },
  SET_PRODUCTS_LIST(state, products) {
    Vue.set(state, "productsList", products);
  },
  SET_META(state, meta) {
    state.meta.page = meta.current_page;
    state.meta.itemsPerPage = meta.per_page;
    state.meta.lastPage = meta.last_page;
    state.meta.totalItems = meta.total;
  },
  SET_PROMOTION(state, promotion) {
    Vue.set(state, "promotion", { ...state.promotion, ...promotion });
  },
  SET_IMPORT_PROMOTIONS_MODAL_VISIBLE(state, visible) {
    Vue.set(state, "isImportPromotionsModalVisible", visible);
  },
  SET_IMPORT_ERROR_FILE(state, file) {
    Vue.set(state, "importErrorFile", file);
  },
  SET_IMPORT_STATUS(state, status) {
    Vue.set(state, "importStatus", status);
  },
};

const actions = {
  initialize: (store) => {
    store.commit("INITIALIZE");
  },
  list: async (store, params) => {
    store.state.isListLoading = true;
    return await service.list(params).then((res) => {
      store.commit("SET_LIST", res.promotions);
      store.commit("SET_META", res.meta);
      store.state.isListLoading = false;
    });
  },
  promotionDetails: async (store, promotion) => {
    store.state.isPromotionDetailsLoading = true;
    return await service.getPromotionDetails(promotion).then((res) => {
      store.commit("SET_PROMOTION_DETAILS", res.products);
      store.state.isPromotionDetailsLoading = false;
    });
  },
  listProducts: async (store, params) => {
    store.state.isProductsListLoading = true;
    return await service.listProducts(params).then((res) => {
      store.commit("SET_PRODUCTS_LIST", res.products);
      store.commit("SET_META", res.meta);
      store.state.isProductsListLoading = false;
    });
  },
  submit: async (store, promotion) => {
    return await service
      .submit(promotion)
      .then((res) => store.commit("SET_PROMOTION", { id: res.id }));
  },
  toggleSelectBatch: async (store, params) => {
    return await service
      .toggleSelectBatch(params)
      .then((res) => store.commit("SET_PROMOTION_DETAILS", res));
  },
  toggleImportPromotionsModal({ commit, state }) {
    commit(
      "SET_IMPORT_PROMOTIONS_MODAL_VISIBLE",
      !state.isImportPromotionsModalVisible
    );
  },
  import: async (store, file) => {
    store.commit("SET_IMPORT_STATUS", "uploading");
    store.commit("SET_IMPORT_ERROR_FILE", null);
    return await service.uploadCSV(file);
  },
  downloadTemplate: () => {
    return service.downloadTemplate();
  },
  downloadFailedResult: ({ state }) => {
    return service.downloadFailedResult(state.importErrorFile);
  },
};
const getters = {
  list: (state) => state.list,
  isListLoading: (state) => state.isListLoading,
  promotionDetails: (state) => state.promotionDetails,
  isPromotionDetailsLoading: (state) => state.isPromotionDetailsLoading,
  productsList: (state) => state.productsList,
  promotion: (state) => state.promotion,
  isProductsListLoading: (state) => state.isProductsListLoading,
  meta: (state) => state.meta,
};

const promotions = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export default promotions;
