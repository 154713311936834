import axios from "@/helpers/axios";
import authService from "@/store/services/auth-service";

const base_url = process.env.VUE_APP_API_BASE_URL;

function list() {
  return axios
    .get(`${base_url}order_origins`, { headers: authService.authHeader() })
    .then((response) => {
      return response.data.order_origins;
    });
}

function listNames() {
  return axios
    .get(`${base_url}order_origins_names`, {
      headers: authService.authHeader(),
    })
    .then((response) => {
      return response.data.order_origins;
    });
}

function add(order_origin) {
  return axios
    .post(`${base_url}order_origins`, order_origin, {
      headers: authService.authHeader(),
    })
    .then((response) => {
      return response.data.order_origin;
    });
}

function update(order_origin) {
  return axios
    .put(`${base_url}order_origins/${order_origin.id}`, order_origin, {
      headers: authService.authHeader(),
    })
    .then((response) => {
      return response.data.order_origin;
    });
}

function destroy(order_origin) {
  return axios
    .delete_method(`${base_url}order_origins/${order_origin.id}`, {
      headers: authService.authHeader(),
    })
    .then((response) => {
      return response.data.order_origin;
    });
}

export default {
  list,
  listNames,
  add,
  update,
  destroy,
};
