import service from "@/store/services/section-service";

const state = {
  isLoadingSections: false,
  listSections: [],
};

const mutations = {
  SET_SECTIONS(state, list_sections) {
    state.listSections = list_sections;
  },

  ADD_CATEGORY(state, section) {
    state.listSections.push(section);
  },

  UPDATE_CATEGORY(state, section) {
    const item = state.listSections.find((item) => item.id === section.id);
    Object.assign(item, section);
  },

  DELETE_CATEGORY(state, section) {
    state.listSections.splice(
      state.listSections.map((o) => o.id).indexOf(section.id),
      1
    );
  },
};

const actions = {
  list: (store) => {
    store.state.isLoadingSections = true;
    return service.list().then((list_sections) => {
      store.commit("SET_SECTIONS", list_sections);
      store.state.isLoadingSections = false;
    });
  },

  add: (store, section) => {
    return service.add(section).then((section) => {
      store.commit("ADD_CATEGORY", section);
      store.dispatch("list", { page: 1, itemsPerPage: 30 });
    });
  },

  update: (store, section) => {
    return service.update(section).then((section) => {
      store.commit("UPDATE_CATEGORY", section);
    });
  },

  destroy: (store, section) => {
    return service.destroy(section).then(() => {
      store.commit("DELETE_CATEGORY", section);
    });
  },
};

const getters = {
  isLoadingSections(state) {
    return state.isLoadingSections;
  },

  listSections(state) {
    return state.listSections;
  },

  activeSections(state) {
    return state.listSections.filter((item) => {
      return item.status == "active";
    });
  },
};

const sections = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default sections;
