import axios from "@/helpers/axios";
import authService from "@/store/services/auth-service";

const base_url = process.env.VUE_APP_API_BASE_URL;

function list() {
  return axios
    .get(`${base_url}transfer_statuses`, { headers: authService.authHeader() })
    .then((response) => {
      return response.data.transfer_statuses;
    });
}

function add(transfer_status) {
  return axios
    .post(`${base_url}transfer_statuses`, transfer_status, {
      headers: authService.authHeader(),
    })
    .then((response) => {
      return response.data.transfer_status;
    });
}

function update(transfer_status) {
  return axios
    .put(
      `${base_url}transfer_statuses/${transfer_status.id}`,
      transfer_status,
      { headers: authService.authHeader() }
    )
    .then((response) => {
      return response.data.transfer_status;
    });
}

function destroy(transfer_status) {
  return axios
    .delete_method(`${base_url}transfer_statuses/${transfer_status.id}`, {
      headers: authService.authHeader(),
    })
    .then((response) => {
      return response.data.transfer_status;
    });
}

export default {
  list,
  add,
  update,
  destroy,
};
