import axios from "@/helpers/axios";
import authService from "@/store/services/auth-service";

const base_url = process.env.VUE_APP_API_BASE_URL;

function list(option) {
  return axios
    .get(`${base_url}accounting`, {
      params: option,
      headers: authService.authHeader(),
    })
    .then((response) => {
      return response.data;
    });
}

async function downloadCSV(option) {
  const res = await axios.get(`${base_url}accounting_csv`, {
    params: option,
    headers: authService.authHeader(),
    responseType: "blob",
  });
  return res.data;
}

async function exportInboundList(option) {
  const res = await axios.get(`${base_url}inbound-list/export`, {
    params: option,
    headers: authService.authHeader(),
    responseType: "blob",
  });
  return res.data;
}
async function exportOutboundList(option) {
  const res = await axios.get(`${base_url}outbound-list/export`, {
    params: option,
    headers: authService.authHeader(),
    responseType: "blob",
  });
  return res.data;
}

export default {
  list,
  downloadCSV,
  exportInboundList,
  exportOutboundList,
};
