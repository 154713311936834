import axios from "@/helpers/axios";
import authService from "@/store/services/auth-service";

const base_url = process.env.VUE_APP_API_BASE_URL;

function list() {
  return axios
    .get(`${base_url}type_prices`, { headers: authService.authHeader() })
    .then((response) => {
      return response.data.type_prices;
    });
}

function add(type_price) {
  return axios
    .post(`${base_url}type_prices`, type_price, {
      headers: authService.authHeader(),
    })
    .then((response) => {
      return response.data.type_price;
    });
}

function update(type_price) {
  return axios
    .put(`${base_url}type_prices/${type_price.id}`, type_price, {
      headers: authService.authHeader(),
    })
    .then((response) => {
      return response.data.type_price;
    });
}

function destroy(type_price) {
  return axios
    .delete_method(`${base_url}type_prices/${type_price.id}`, {
      headers: authService.authHeader(),
    })
    .then((response) => {
      return response.data.type_price;
    });
}

export default {
  list,
  add,
  update,
  destroy,
};
