import Vue from "vue";
import service from "@/store/services/accounting-service";

const state = {
  isLoadingData: false,
  listInbounds: [],
  listOutbounds: [],
  meta: {},
};

const mutations = {
  SET_DATA: (state, payload) => {
    state.listProducts = payload;
  },
  SET_INBOUND: (state, payload) => {
    state.listInbounds = payload;
  },
  SET_OUTBOUND: (state, payload) => {
    state.listOutbounds = payload;
  },

  SET_META(state, data) {
    if (data.meta) {
      state.meta.page = data.meta.current_page;
      state.meta.itemsPerPage = data.meta.per_page;
      state.meta.lastPage = data.meta.last_page;
      state.meta.totalItems = data.meta.total;
    }
  },

  CLEAN_LIST(state) {
    Vue.set(state, "listProducts", []);
  },
};

const actions = {
  listInbounds: (store, option) => {
    store.state.isLoadingData = true;
    return service
      .list(option)
      .then((payload) => {
        store.commit("SET_INBOUND", payload.products);
        store.commit("SET_META", payload);
        store.state.isLoadingData = false;
      })
      .catch((error) => {
        store.state.isLoadingData = false;
        throw error;
      });
  },

  listOutbounds: (store, option) => {
    store.state.isLoadingData = true;
    return service
      .list(option)
      .then((payload) => {
        store.commit("SET_OUTBOUND", payload.products);
        store.commit("SET_META", payload);
        store.state.isLoadingData = false;
      })
      .catch((error) => {
        store.state.isLoadingData = false;
        throw error;
      });
  },

  downloadCSV: (store, option) => {
    return service.downloadCSV(option);
  },
};

const getters = {
  isLoadingData(state) {
    return state.isLoadingData;
  },

  listInbounds(state) {
    return state.listInbounds;
  },
  listOutbounds(state) {
    return state.listOutbounds;
  },
  meta(state) {
    return state.meta;
  },
};

const accounting = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default accounting;
