import axios from "@/helpers/axios";
import authService from "@/store/services/auth-service";

const base_url = process.env.VUE_APP_API_BASE_URL;

async function list(params) {
  const res = await axios.get(`${base_url}providers`, {
    params,
    headers: authService.authHeader(),
  });
  return res.data;
}
async function add(provider) {
  const res = await axios.post(`${base_url}providers`, provider, {
    headers: authService.authHeader(),
  });
  return res.data.provider;
}

async function update(provider) {
  const res = await axios.put(`${base_url}providers/${provider.id}`, provider, {
    headers: authService.authHeader(),
  });
  return res.data.provider;
}
async function toggleStatus(provider) {
  const res = await axios.put(
    `${base_url}providers/toggle_status/${provider.id}`,
    {},
    { headers: authService.authHeader() }
  );
  return res.data.provider;
}

async function destroy(provider) {
  return await axios.delete_method(`${base_url}providers/${provider.id}`, {
    headers: authService.authHeader(),
  });
}

async function downloadCSV(option) {
  const res = await axios.get(`${base_url}providers_export_csv`, {
    params: option,
    headers: authService.authHeader(),
    responseType: "blob",
  });
  return res.data;
}

async function show(options) {
  const res = await axios.get(`${base_url}providers/${options.id}`, {
    headers: authService.authHeader(),
  });
  return res.data.provider;
}

export default {
  list,
  add,
  update,
  destroy,
  toggleStatus,
  downloadCSV,
  show,
};
