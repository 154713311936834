import Vue from "vue";
import service from "@/store/services/coupons-service";
import Coupon from "@/classes/coupon.class.js";

const state = {
  list: [],
  isLoading: false,
  coupon: new Coupon({ type: "flat", start_at: null, end_at: null }),
  meta: {},
  isVisibleDeleteDialog: false,
};

const mutations = {
  INITIALIZE() {
    Object.assign(state, {
      list: [],
      isLoading: false,
      coupon: new Coupon({ type: "flat", start_at: null, end_at: null }),
      meta: {},
    });
  },
  CLEAR_LIST(state) {
    state.list = [];
  },
  SET_LIST(state, coupons) {
    state.list = coupons;
  },
  SET_META(state, meta) {
    state.meta = {
      ...meta,
      page: meta.current_page,
      itemsPerPage: meta.per_page,
      lastPage: meta.last_page,
      totalItems: meta.total,
    };
  },
  ADD_COUPON(state, coupon) {
    state.list.push(Object.assign({}, coupon));
  },
  UPDATE_COUPON(state, coupon) {
    const item = state.list.find((item) => item.id === coupon.id);
    if (item) {
      Object.assign(item, coupon);
    }
  },
  SET_COUPON(state, coupon) {
    Vue.set(state, "coupon", coupon);
  },
  IS_VISIBLE_DELETE_DIALOG(state, is_visible) {
    Vue.set(state, "isVisibleDeleteDialog", is_visible);
  },
  DELETE_COUPON(state, coupon) {
    state.list.splice(state.list.map((o) => o.id).indexOf(coupon.id), 1);
  },

  SET_LOADING(state, value) {
    state.isLoading = value;
  },
};

const actions = {
  initialize: (store) => {
    store.commit("INITIALIZE");
  },
  loadCoupon: async ({ state, commit }, couponId) => {
    state.isLoading = true;
    const res = await service.show(couponId);
    res.status = res.status === "active";
    commit("SET_COUPON", res);
    state.isLoading = false;
  },
  list: async (store, params) => {
    store.state.isLoading = true;
    try {
      const res = await service.list(params);
      store.commit("SET_LIST", res.coupons);
      store.commit("SET_META", res.meta);
    } finally {
      store.state.isLoading = false;
    }
  },
  async add({ commit }, coupon) {
    const res = await service.add(coupon);
    commit("ADD_COUPON", res);
    return true;
  },
  async clone({ commit }, item) {
    const coupon = await service.clone(item.id);
    commit("ADD_COUPON", coupon);
    return true;
  },
  update: async ({ commit }, coupon) => {
    const res = await service.update(coupon);
    commit("UPDATE_COUPON", res);
    return true;
  },
  toggleStatus: async ({ state, commit }, coupon) => {
    state.isLoading = true;
    try {
      const res = await service.toggleStatus(coupon);
      commit("UPDATE_COUPON", res);
    } finally {
      state.isLoading = false;
    }
  },
  openDeleteForm: ({ commit }, coupon) => {
    commit("IS_VISIBLE_DELETE_DIALOG", true);
    commit("SET_COUPON", Object.assign({}, coupon));
  },
  closeForm: (store) => {
    store.commit("IS_VISIBLE_DELETE_DIALOG", false);
    store.commit("SET_COUPON", {});
  },
  destroy: async ({ state, commit }) => {
    await service.destroy(state.coupon);
    commit("DELETE_COUPON", state.coupon);
    return true;
  },
};
const getters = {
  list: (state) => state.list,
  isLoading: (state) => state.isLoading,
  coupon: (state) => state.coupon,
  meta: (state) => state.meta,
};

const coupons = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export default coupons;
