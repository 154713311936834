import Localbase from "localbase";

let db = new Localbase("db");
function getOrders() {
  return db.collection("orders").get();
}

function getOrdersHistory() {
  return db.collection("orders").get();
}

function setOrder(tracking_number, data) {
  return db.collection("orders").doc(tracking_number).set(data);
}

function setOrderHistory(tracking_number, data) {
  return db.collection("orders_history").doc(tracking_number).set(data);
}

function deleteOrderByTrackingNumber(tracking_number) {
  db.collection("orders").doc(tracking_number).delete();
  db.collection("orders_history")
    .doc(tracking_number)
    .update({ status: "Synced" });
}

async function updateOrderByTrackingNumber(tracking_number, data) {
  const ordersUpdate = db
    .collection("orders")
    .doc(tracking_number)
    .update(data);
  const ordersHistoryUpdate = db
    .collection("orders_history")
    .doc(tracking_number)
    .update(data);

  await Promise.all([ordersUpdate, ordersHistoryUpdate]);
}

function updateOrderHistoryStatus(tracking_number, status) {
  return db
    .collection("orders_history")
    .doc(tracking_number)
    .update({ status });
}

function markOrderAsLogged(tracking_number) {
  db.collection("orders").doc(tracking_number).update({ logged: true });
}

export default {
  getOrders,
  getOrdersHistory,
  setOrder,
  setOrderHistory,
  deleteOrderByTrackingNumber,
  updateOrderByTrackingNumber,
  updateOrderHistoryStatus,
  markOrderAsLogged,
};
