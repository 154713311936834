import axios from "@/helpers/axios";
import authService from "@/store/services/auth-service";

const base_url = process.env.VUE_APP_API_BASE_URL;

async function list(params) {
  const res = await axios.get(`${base_url}transfer-products`, {
    params: params,
    headers: authService.authHeader(),
  });
  return res.data;
}

async function getInvoice(transfer) {
  const res = await axios.get(
    `${base_url}generate_invoice_transfer_a4/${transfer.id}`,
    { headers: authService.authHeader(), responseType: "blob" }
  );
  return res.data;
}
async function getDeliveryNote(transfer) {
  const res = await axios.get(
    `${base_url}generate_delivery_note_transfer_a4/${transfer.id}`,
    { headers: authService.authHeader(), responseType: "blob" }
  );
  return res.data;
}

async function show(options) {
  const res = await axios.get(
    `${base_url}transfer-products/${options.id}?include=products`,
    {
      params: options,
      headers: authService.authHeader(),
    }
  );
  return res.data.transfer;
}

async function add(transfer) {
  const res = await axios.post(`${base_url}transfer-products`, transfer, {
    headers: authService.authHeader(),
  });
  return res.data.transfer;
}

async function addToCart(transferItem) {
  const res = await axios.put(
    `${base_url}transfer-products/${transferItem.transfer_id}/products/${transferItem.id}`,
    transferItem,
    {
      headers: authService.authHeader(),
    }
  );
  return res.data.transfer;
}

async function removeFromCart(transferItem) {
  const res = await axios.delete_method(
    `${base_url}transfer-products/${transferItem.transfer_id}/products/${transferItem.id}`,
    {
      headers: authService.authHeader(),
    }
  );
  return res.data.transfer;
}

async function submitTransfer(params) {
  const { expected_delivery_date, note } = params;
  const res = await axios.put(
    `${base_url}transfer-products/${params.transfer_id}/submit`,
    {
      expected_delivery_date,
      note,
    },
    {
      headers: authService.authHeader(),
    }
  );
  return res.data.transfer;
}

async function cancelTransfer(params) {
  const res = await axios.put(
    `${base_url}transfer-products/${params.transfer_id}/cancel`,
    {},
    {
      headers: authService.authHeader(),
    }
  );
  return res.data.transfer;
}

async function update(transfer) {
  const res = await axios.put(
    `${base_url}transfer-products/${transfer.id}`,
    transfer,
    { headers: authService.authHeader() }
  );
  return res.data.transfer;
}

async function updateOutgoing(transfer) {
  const res = await axios.put(
    `${base_url}transfer-products/${transfer.id}/update-outgoing`,
    transfer,
    { headers: authService.authHeader() }
  );
  return res.data.transfer;
}
async function validateReception(transfer) {
  const res = await axios.put(
    `${base_url}transfer-products/${transfer.id}/validate`,
    {},
    { headers: authService.authHeader() }
  );
  return res.data.transfer;
}

function destroy(transfer) {
  return axios.delete_method(`${base_url}transfer-products/${transfer.id}`, {
    headers: authService.authHeader(),
  });
}

async function uploadCSV(file) {
  const res = await axios.post(
    `${base_url}transfers/upload_csv`,
    { csv: file },
    {
      headers: {
        "Content-Type": "multipart/form-data",
        ...authService.authHeader(),
      },
    }
  );
  return res.data;
}

async function downloadTemplate() {
  const res = await axios.get(
    `${base_url}transfer-products/download/import-template`,
    {
      headers: authService.authHeader(),
      responseType: "blob",
    }
  );
  return res.data;
}

async function exportCSV(option) {
  const res = await axios.get(`${base_url}transfer-products/export/csv`, {
    params: { ...option, details_export: true },
    headers: authService.authHeader(),
    responseType: "blob",
  });
  return res.data;
}
async function exportCSVResult(option) {
  const res = await axios.get(`${base_url}transfer-products/export/csv`, {
    params: option,
    headers: authService.authHeader(),
    responseType: "blob",
  });
  return res.data;
}
async function createFromExpired(options) {
  const res = await axios.post(
    `${base_url}transfer-products/create-from-expired`,
    options,
    {
      headers: authService.authHeader(),
    }
  );
  return res.data.transfer;
}

export default {
  list,
  getInvoice,
  getDeliveryNote,
  show,
  add,
  addToCart,
  removeFromCart,
  submitTransfer,
  cancelTransfer,
  update,
  updateOutgoing,
  destroy,
  validateReception,
  uploadCSV,
  exportCSV,
  downloadTemplate,
  exportCSVResult,
  createFromExpired,
};
