import Vue from "vue";
import service from "@/store/services/role-service";

const state = {
  isVisibleDialog: false,
  isVisibleDeleteDialog: false,
  editedRole: { name: "", permission_ids: [] },

  isLoadingRoles: false,
  listRoles: [],
  meta: {},
};

const mutations = {
  IS_VISIBLE_DIALOG(state, is_visible) {
    Vue.set(state, "isVisibleDialog", is_visible);
  },

  IS_VISIBLE_DELETE_DIALOG(state, is_visible) {
    Vue.set(state, "isVisibleDeleteDialog", is_visible);
  },
  EDITED_ROLE(state, role) {
    Vue.set(state, "editedRole", role);
  },
  SET_ROLES(state, list_roles) {
    state.listRoles = list_roles;
  },

  ADD_ROLE(state, role) {
    state.listRoles.push(role);
  },

  UPDATE_ROLE(state, role) {
    const item = state.listRoles.find((item) => item.id === role.id);
    Object.assign(item, role);
  },

  DELETE_ROLE(state, role) {
    state.listRoles.splice(
      state.listRoles.map((o) => o.id).indexOf(role.id),
      1
    );
  },

  SET_META(state, data) {
    if (data.meta) {
      state.meta.page = data.meta.current_page;
      state.meta.itemsPerPage = data.meta.per_page;
      state.meta.lastPage = data.meta.last_page;
      state.meta.totalItems = data.meta.total;
    }
  },
};

const actions = {
  openEditForm: (store, payload) => {
    service.show(payload).then((data) => {
      store.commit("IS_VISIBLE_DIALOG", true);
      store.commit("EDITED_ROLE", Object.assign({}, data.role));
    });
  },

  openDeleteForm: (store, role) => {
    store.commit("IS_VISIBLE_DELETE_DIALOG", true);
    store.commit("EDITED_ROLE", Object.assign({}, role));
  },

  closeForm: (store) => {
    store.commit("IS_VISIBLE_DIALOG", false);
    store.commit("IS_VISIBLE_DELETE_DIALOG", false);
    store.commit("EDITED_ROLE", {});
  },
  list: (store, params) => {
    store.state.isLoadingRoles = true;
    service.list(params).then((data) => {
      store.commit("SET_ROLES", data.roles);
      store.commit("SET_META", data);
      store.state.isLoadingRoles = false;
    });
  },

  add: (store, role) => {
    return service.add(role).then((role) => {
      store.commit("ADD_ROLE", role);
      store.dispatch("list", { page: 1, itemsPerPage: 30 });
    });
  },

  update: (store, role) => {
    return service.update(role).then((role) => {
      store.commit("UPDATE_ROLE", role);
    });
  },

  destroy: (store, role) => {
    return service.destroy(role).then(() => {
      store.commit("DELETE_ROLE", role);
    });
  },
};

const getters = {
  isLoadingRoles(state) {
    return state.isLoadingRoles;
  },

  editedRole(state) {
    return state.editedRole;
  },

  listRoles(state) {
    return state.listRoles;
  },

  listOwnedRoles(state) {
    return state.listRoles.filter((item) => {
      return item.store_id;
    });
  },

  meta(state) {
    return state.meta;
  },

  isVisibleDialog(state) {
    return state.isVisibleDialog;
  },
};

const roles = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default roles;
