import Vue from "vue";
import service from "@/store/services/inbound-product-service";

const state = {
  isVisibleDialog: false,
  isVisibleShow: false,
  isVisibleDeleteDialog: false,
  isLoadingInbounds: false,
  isLoadingInbound: false,
  filters: {},

  editedInbound: {},
  inbound: {},
  listProducts: [],
  listInbounds: [],
  meta: {},
};

const mutations = {
  IS_VISIBLE_DIALOG(state, is_visible) {
    Vue.set(state, "isVisibleDialog", is_visible);
  },

  IS_VISIBLE_DELETE_DIALOG(state, is_visible) {
    Vue.set(state, "isVisibleDeleteDialog", is_visible);
  },

  IS_VISIBLE_SHOW(state, is_visible) {
    Vue.set(state, "isVisibleShow", is_visible);
  },

  EDITED_INBOUND(state, inbound) {
    Vue.set(state, "editedInbound", inbound);
  },

  EDITED_PRODUCTS(state, products) {
    Vue.set(state.editedInbound, "products", products);
  },

  SET_PRODUCTS(state, products) {
    Vue.set(state.inbound, "products", products);
  },

  SET_FILTERS(state, filters) {
    Vue.set(state.inbound, "filters", filters);
  },

  SET_INBOUNDS(state, inbounds) {
    state.listInbounds = inbounds;
  },

  SET_INBOUND(state, inbound) {
    state.inbound = inbound;
  },

  ADD_INBOUND(state, inbound) {
    state.listInbounds.push(inbound);
  },

  CLEAN_LIST(state) {
    Vue.set(state, "listInbounds", []);
  },

  UPDATE_INBOUND(state, inbound) {
    const item = state.listInbounds.find((item) => item.id === inbound.id);
    if (item) Object.assign(item, inbound);
  },

  UPDATE_PRODUCT(state, product) {
    const item = state.listProducts.find((item) => item.id === product.id);
    if (item) Object.assign(item, product);
    else state.listProducts.push(product);
  },

  DELETE_INBOUND(state, inbound) {
    state.listInbounds.splice(
      state.listInbounds.map((item) => item.id).indexOf(inbound.id),
      1
    );
  },

  SET_META(state, data) {
    state.meta.page = data.meta.current_page;
    state.meta.itemsPerPage = data.meta.per_page;
    state.meta.lastPage = data.meta.last_page;
    state.meta.totalItems = data.meta.total;
  },
};

const actions = {
  openEditForm: async (store, inbound) => {
    await service.show({ id: inbound.id }).then((inbound) => {
      store.commit("EDITED_INBOUND", inbound);
      store.commit("SET_INBOUND", inbound);
      store.commit("IS_VISIBLE_DIALOG", true);
    });
  },

  openDeleteForm: (store, inbound) => {
    store.commit("IS_VISIBLE_DELETE_DIALOG", true);
    store.commit("SET_INBOUND", inbound);
    store.commit("EDITED_INBOUND", Object.assign({}, inbound));
  },

  openShowForm: (store, inbound) => {
    store.commit("IS_VISIBLE_SHOW", true);
    store.commit("SET_INBOUND", inbound);
    store.commit("EDITED_INBOUND", Object.assign({}, inbound));
  },

  closeForm: (store) => {
    store.commit("IS_VISIBLE_DIALOG", false);
    store.commit("IS_VISIBLE_DELETE_DIALOG", false);
    store.commit("IS_VISIBLE_SHOW", false);
    store.commit("EDITED_INBOUND", {});
    store.commit("SET_INBOUND", {});
  },

  list: (store, params) => {
    store.state.isLoadingInbounds = true;
    return service
      .list(params)
      .then((data) => {
        store.commit("SET_INBOUNDS", data.inbounds);
        store.commit("SET_META", data);
        store.state.isLoadingInbounds = false;
      })
      .catch((error) => {
        store.state.isLoadingInbounds = false;
        throw error;
      });
  },

  listProducts(state) {
    return state.listProducts;
  },

  getInvoice: (store, inbound) => {
    return service.getInvoice(inbound);
  },

  add: (store, inbound) => {
    return service.add(inbound).then((item) => {
      store.commit("ADD_INBOUND", item);
      store.dispatch("list", {
        page: 1,
        itemsPerPage: 30,
        inbound_status_name: "open",
      });
    });
  },

  update: (store, inbound) => {
    return service.update(inbound).then((item) => {
      store.commit("UPDATE_INBOUND", item);
    });
  },

  updateProductPrices: (store, payload) => {
    return service.updateProductPrices(payload).then((item) => {
      store.commit("UPDATE_INBOUND", item);
      store.commit("SET_PRODUCTS", item.products);
    });
  },

  confirm: (store, inbound) => {
    return service.confirm(inbound).then((item) => {
      store.commit("UPDATE_INBOUND", item);
    });
  },

  reopen: (store, inbound) => {
    return service.reopen(inbound).then((item) => {
      store.commit("UPDATE_INBOUND", item);
    });
  },

  destroy: (store) => {
    if (store.state.editedInbound) {
      const inbound = store.state.editedInbound;
      return service.destroy(inbound).then(() => {
        store.commit("DELETE_INBOUND", inbound);
      });
    }
  },
  uploadCSV: (store, payload) => {
    return service.uploadCSV(payload).then((data) => {
      store.commit("SET_INBOUNDS", data.inbounds);
      store.commit("SET_META", data);
    });
  },

  downloadCSV: (store, option) => {
    return service.downloadCSV(option);
  },
  downloadCSVResult: (store, option) => {
    return service.downloadCSVResult(option);
  },
};

const getters = {
  isVisibleDialog(state) {
    return state.isVisibleDialog;
  },

  isLoadingInbounds(state) {
    return state.isLoadingInbounds;
  },

  isLoadingInbound(state) {
    return state.isLoadingInbound;
  },

  editedIndex(state) {
    return state.editedIndex;
  },

  editedInbound(state) {
    return state.editedInbound;
  },

  inbound(state) {
    return state.inbound;
  },

  listInbounds(state) {
    return state.listInbounds;
  },

  openInbounds(state) {
    return state.listInbounds.filter((item) => {
      return item.inboundStatus.name === "open";
    });
  },

  meta(state) {
    return state.meta;
  },

  filters(state) {
    return state.filters;
  },
};

const inboundProducts = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default inboundProducts;
