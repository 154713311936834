import Vue from "vue";
import service from "@/store/services/supply-order-service";

const state = {
  isVisibleDialog: false,
  isVisibleShow: false,
  isVisibleDeleteDialog: false,
  isLoadingSupplyOrders: false,
  isLoadingSupplyOrder: false,
  isLoadingTypes: false,

  editedSupplyOrder: {},
  supplyOrder: {},

  list: [],
  listTypes: [],
  meta: {},
  filters: {},
  listsetting: {},
  params: {},
  listProducts: [],
  selected: 1,
};

const mutations = {
  IS_VISIBLE_DIALOG(state, is_visible) {
    Vue.set(state, "isVisibleDialog", is_visible);
  },

  IS_VISIBLE_DELETE_DIALOG(state, is_visible) {
    Vue.set(state, "isVisibleDeleteDialog", is_visible);
  },

  IS_VISIBLE_SHOW(state, is_visible) {
    Vue.set(state, "isVisibleShow", is_visible);
  },

  EDITED_SUPPLY_ORDER(state, supplyOrder) {
    Vue.set(state, "editedSupplyOrder", supplyOrder);
    Vue.set(state, "supplyOrder", supplyOrder);
  },

  SupplyOrder(state, supplyOrder) {
    Vue.set(state, "supplyOrder", supplyOrder);
  },

  EDITED_PRODUCTS(state, products) {
    Vue.set(state.editedSupplyOrder, "products", products);
  },

  SET_SUPPLY_ORDER(state, supplyOrders) {
    state.list = supplyOrders;
  },
  SET_SELECTED(state, selected) {
    state.selected = selected;
  },

  ADD_SUPPLY_ORDER(state, supplyOrder) {
    state.list.push(supplyOrder);
  },

  CLEAN_LIST(state) {
    Vue.set(state, "list", []);
  },

  UPDATE_SUPPLY_ORDER(state, supplyOrder) {
    const item = state.list.find((item) => item.id === supplyOrder.id);
    Object.assign(item, supplyOrder);
  },

  UPDATE_PRODUCT(state, product) {
    const item = state.listProducts.find((item) => item.id === product.id);
    if (item) Object.assign(item, product);
    else state.listProducts.push(product);
  },

  DELETE_SUPPLY_ORDER(state, supplyOrder) {
    state.list.splice(
      state.list.map((item) => item.id).indexOf(supplyOrder.id),
      1
    );
  },

  SET_META(state, data) {
    state.meta.page = data.meta.current_page;
    state.meta.itemsPerPage = data.meta.per_page;
    state.meta.lastPage = data.meta.last_page;
    state.meta.totalItems = data.meta.total;
    state.meta.manual_cancellation = data.meta.manual_cancellation;
  },

  SET_SETTING(state, procurement_settings) {
    state.listsetting = procurement_settings;
  },

  SET_PARAMS(state, payload) {
    Vue.set(state, "params", payload);
  },
  SET_TYPES(state, delivery_types) {
    state.listTypes = delivery_types;
  },
  updateSupplyOrder(state, supplyOrder) {
    Vue.set(state, "supplyOrder", supplyOrder);
  },
  SET_IMPORT_PROCUREMENTS_MODAL_VISIBLE(state, visible) {
    Vue.set(state, "isImportProcurementsModalVisible", visible);
  },
  SET_IMPORT_ERROR_FILE(state, file) {
    Vue.set(state, "importErrorFile", file);
  },
  SET_IMPORT_STATUS(state, status) {
    Vue.set(state, "importStatus", status);
  },
};

const actions = {
  openEditForm: (store, supplyOrder) => {
    store.commit("IS_VISIBLE_DIALOG", true);
    store.commit("EDITED_SUPPLY_ORDER", Object.assign({}, supplyOrder));
  },

  openDeleteForm: (store, supplyOrder) => {
    store.commit("IS_VISIBLE_DELETE_DIALOG", true);
    store.commit("EDITED_SUPPLY_ORDER", Object.assign({}, supplyOrder));
  },

  openShowForm: (store, supplyOrder) => {
    store.commit("IS_VISIBLE_SHOW", true);
    store.commit("SupplyOrder", Object.assign({}, supplyOrder));
  },

  closeForm: (store) => {
    store.commit("IS_VISIBLE_DIALOG", false);
    store.commit("IS_VISIBLE_DELETE_DIALOG", false);
    store.commit("IS_VISIBLE_SHOW", false);
    store.commit("EDITED_SUPPLY_ORDER", {});
    store.commit("SupplyOrder", {});
  },

  list: (store, params) => {
    store.state.isLoadingSupplyOrders = true;
    return service
      .list(params)
      .then((data) => {
        store.commit("SET_SUPPLY_ORDER", data.supplyOrders);
        store.commit("SET_META", data);
        store.state.isLoadingSupplyOrders = false;
      })
      .catch((error) => {
        store.state.isLoadingSupplyOrders = false;
        throw error;
      });
  },

  getInvoice: (supplyOrder) => {
    return service.getInvoice(supplyOrder);
  },

  add: (store, supplyOrder) => {
    return service.add(supplyOrder).then((item) => {
      store.commit("ADD_SUPPLY_ORDER", item);
      // store.dispatch('list',{page:1,itemsPerPage:30})
    });
  },

  update: (store, supplyOrder) => {
    return service.update(supplyOrder).then((item) => {
      store.commit("UPDATE_SUPPLY_ORDER", item);
    });
  },

  updateStatus: (store, supplyOrder) => {
    return service.updateStatus(supplyOrder).then((item) => {
      store.commit("UPDATE_SUPPLY_ORDER", item);
    });
  },
  updateDeliveryDate: (store, supplyOrder) => {
    return service.updateDeliveryDate(supplyOrder).then((item) => {
      store.commit("UPDATE_SUPPLY_ORDER", item);
    });
  },

  confirm: (store, supplyOrder) => {
    return service.confirm(supplyOrder).then((item) => {
      store.commit("UPDATE_SUPPLY_ORDER", item);
    });
  },

  setToReceived: (store, supplyOrder) => {
    return service.setToReceived(supplyOrder).then((item) => {
      store.commit("UPDATE_SUPPLY_ORDER", item);
    });
  },

  destroy: (store, supplyOrder) => {
    return service.destroy(supplyOrder).then(() => {
      store.commit("DELETE_SUPPLY_ORDER", supplyOrder);
    });
  },
  uploadCSV: (store, payload) => {
    return service.uploadCSV(payload).then((data) => {
      store.commit("SET_SUPPLY_ORDER", data.supplyOrders);
      store.commit("SET_META", data);
    });
  },

  setParams: (store, params) => {
    store.commit("SET_PARAMS", params);
  },

  downloadCSV: (store, option) => {
    return service.downloadCSV(option);
  },
  updateProduct: (store, params) => {
    return service.updateProduct(params).then((item) => {
      store.commit("UPDATE_PRODUCT", item);
    });
  },

  addToCart: (store, params) => {
    return service.addToCart(params).then((supplyOrder) => {
      store.commit("SupplyOrder", supplyOrder);
    });
  },

  removeFromCart: (store, params) => {
    return service.removeFromCart(params).then((supplyOrder) => {
      store.commit("SupplyOrder", supplyOrder);
    });
  },
  updateSupplyOrder: (store, params) => {
    return service.updateSupplyOrder(params).then((supplyOrder) => {
      store.commit("SupplyOrder", supplyOrder);
    });
  },

  submitSupplyOrder: (store, params) => {
    console.log(params);
    return service.submitSupplyOrder(params);
  },

  types: (store, params) => {
    store.state.isLoadingTypes = true;
    return service
      .listDelivery(params)
      .then((data) => {
        store.commit("SET_TYPES", data.delivery_types);
        store.state.isLoadingTypes = false;
      })
      .catch((error) => {
        store.state.isLoadingTypes = false;
        throw error;
      });
  },
  duplicate: (store, params) => {
    return service.duplicate(params);
  },
  cancel: (store, params) => {
    console.log(params);
    return service.cancel(params);
  },
  procurementSettings: (store, params) => {
    console.log(params);
    return service.settings(params);
  },
  settinglist: () => {
    return service.settingsList();
  },
  toggleImportProcurementsModal({ commit, state }) {
    commit(
      "SET_IMPORT_PROCUREMENTS_MODAL_VISIBLE",
      !state.isImportProcurementsModalVisible
    );
  },
  import: async (store, file) => {
    store.commit("SET_IMPORT_STATUS", "uploading");
    store.commit("SET_IMPORT_ERROR_FILE", null);
    return await service.uploadCSVProcurements(file);
  },
  downloadTemplate: () => {
    return service.downloadTemplate();
  },
  downloadFailedResult: ({ state }) => {
    return service.downloadFailedResult(state.importErrorFile);
  },
};

const getters = {
  isVisibleDialog(state) {
    return state.isVisibleDialog;
  },

  isLoadingSupplyOrders(state) {
    return state.isLoadingSupplyOrders;
  },

  isLoadingSupplyOrder(state) {
    return state.isLoadingSupplyOrder;
  },

  editedIndex(state) {
    return state.editedIndex;
  },

  editedSupplyOrder(state) {
    return state.editedSupplyOrder;
  },

  supplyOrder(state) {
    return state.supplyOrder;
  },

  list(state) {
    return state.list;
  },
  listTypes(state) {
    return state.listTypes;
  },
  listsetting(state) {
    return state.listsetting;
  },
  SET_FILTERS(state, filters) {
    Vue.set(state.supplyOrder, "filters", filters);
  },

  listProducts(state) {
    return state.listProducts;
  },

  openSupplyOrders(state) {
    return state.list.filter((item) => item.status === "open");
  },

  meta(state) {
    return state.meta;
  },
  params(state) {
    return state.params;
  },
  filters(state) {
    return state.filters;
  },
  selected(state) {
    return state.selected;
  },
};

const supplyOrders = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default supplyOrders;
