import axios from "@/helpers/axios";
import authService from "@/store/services/auth-service";

const base_url = process.env.VUE_APP_API_BASE_URL;

function list() {
  return axios
    .get(`${base_url}wilayas`, { headers: authService.authHeader() })
    .then((response) => {
      return response.data.wilayas;
    });
}

function add(wilaya) {
  return axios
    .post(`${base_url}wilayas`, wilaya, { headers: authService.authHeader() })
    .then((response) => {
      return response.data.wilaya;
    });
}

function update(wilaya) {
  return axios
    .put(`${base_url}wilayas/${wilaya.id}`, wilaya, {
      headers: authService.authHeader(),
    })
    .then((response) => {
      return response.data.wilaya;
    });
}

function destroy(wilaya) {
  return axios
    .delete_method(`${base_url}wilayas/${wilaya.id}`, {
      headers: authService.authHeader(),
    })
    .then((response) => {
      return response.data.wilaya;
    });
}

export default {
  list,
  add,
  update,
  destroy,
};
