import Vue from "vue";
import service from "@/store/services/checkout-service";

const state = {
  isLoadingData: false,
  isVisibleShow: false,
  checkout: {},
  list: [],
  meta: {},
};

const mutations = {
  IS_VISIBLE_DIALOG(state, is_visible) {
    Vue.set(state, "isVisibleShow", is_visible);
  },

  SET_DATA: (state, payload) => {
    state.list = payload;
  },

  SET_META(state, data) {
    if (data.meta) {
      state.meta.page = data.meta.current_page;
      state.meta.itemsPerPage = data.meta.per_page;
      state.meta.lastPage = data.meta.last_page;
      state.meta.totalItems = data.meta.total;
    }
  },

  SET_CHECKOUT(state, checkout) {
    Vue.set(state, "checkout", checkout);
  },

  CLEAN_LIST(state) {
    Vue.set(state, "list", []);
  },
};

const actions = {
  openShowForm: async (store, checkout) => {
    await service.show({ id: checkout.id }).then((checkout) => {
      store.commit("IS_VISIBLE_DIALOG", true);
      store.commit("SET_CHECKOUT", Object.assign({}, checkout));
    });
  },

  closeForm: (store) => {
    store.commit("IS_VISIBLE_DIALOG", false);
    store.commit("SET_CHECKOUT", {});
  },

  list: (store, option) => {
    store.state.isLoadingData = true;
    return service
      .list(option)
      .then((payload) => {
        store.commit("SET_DATA", payload.checkouts);
        store.commit("SET_META", payload);
        store.state.isLoadingData = false;
      })
      .catch((error) => {
        store.state.isLoadingData = false;
        throw error;
      });
  },

  getPDF_A4: (store, checkout) => {
    store.state.isLoadingFile = true;
    return service.getPDF_A4(checkout).then(function (response) {
      store.state.isLoadingFile = false;
      return response;
    });
  },
};

const getters = {
  isLoadingData(state) {
    return state.isLoadingData;
  },
  isVisibleShow(state) {
    return state.isVisibleShow;
  },
  getCheckouts(state) {
    return state.list;
  },
  getCheckout(state) {
    return state.checkout;
  },
  meta(state) {
    return state.meta;
  },
};

const accounting = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default accounting;
