export class GroupedProduct {
  id;
  name;
  name_marketing;
  type = "pack";
  barcodes = [new Barcode(null, true)];
  product_info = new ProductInfo();
  bundle_items;
  point_of_supply;
  imported;
  purchase_price = 0;
  purchase_price_ht = 0;
  price = 0;
  price_ht = 0;
  purchase_tva = 0;
  tva = 0;
  price = 0;
  qte = 0;
  quantity = 0;
  image;
  category_id;
  brand;
}
export class Barcode {
  code;
  is_main;
  constructor(code, is_main) {
    this.code = code;
    this.is_main = is_main;
  }
}

export class Item {
  id;
  qte_in_bundle;
}
export class ProductInfo {
  item_net_weight = null;
  item_net_weight_unit = null;
  item_gross_weight = null;
  item_gross_weight_unit = null;
  item_net_volume = null;
  item_net_volume_unit = null;
  item_gross_volume = null;
  item_gross_volume_unit = null;
}
