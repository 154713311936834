import axios from "@/helpers/axios";
import authService from "@/store/services/auth-service";

const base_url = process.env.VUE_APP_API_BASE_URL;

function list(wilaya_id) {
  return axios
    .get(`${base_url}cities`, {
      params: { wilaya_id },
      headers: authService.authHeader(),
    })
    .then((response) => {
      return response.data.cities;
    });
}

function search(countryCode, queryString) {
  return axios
    .get(`${base_url}cities/search/${countryCode}/${queryString}`, {
      headers: authService.authHeader(),
    })
    .then((response) => {
      return response.data.cities;
    });
}
function getCity(cityId) {
  return axios
    .get(`${base_url}cities/${cityId}`, { headers: authService.authHeader() })
    .then((response) => {
      return response.data.city;
    });
}
function add(city) {
  return axios
    .post(`${base_url}cities`, city, { headers: authService.authHeader() })
    .then((response) => {
      return response.data.city;
    });
}

function update(city) {
  return axios
    .put(`${base_url}cities/${city.id}`, city, {
      headers: authService.authHeader(),
    })
    .then((response) => {
      return response.data.city;
    });
}

function destroy(city) {
  return axios
    .delete_method(`${base_url}cities/${city.id}`, {
      headers: authService.authHeader(),
    })
    .then((response) => {
      return response.data.city;
    });
}

export default {
  list,
  search,
  getCity,
  add,
  update,
  destroy,
};
