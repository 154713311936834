import axios from "@/helpers/axios";
import authService from "@/store/services/auth-service";

const base_url = process.env.VUE_APP_API_BASE_URL;

async function list(params) {
  return axios
    .get(`${base_url}alerts`, { params, headers: authService.authHeader() })
    .then((response) => {
      return response.data;
    });
}

async function attachAlerts(product) {
  const payload = { hubs: product.hubs };
  const res = await axios.put(
    `${base_url}alerts/attach-product/${product.id}`,
    payload,
    {
      headers: authService.authHeader(),
    }
  );
  return res.data.product;
}

async function exportAlertsCSV(option) {
  const res = await axios.get(`${base_url}products_export_alerts_csv`, {
    params: option,
    headers: authService.authHeader(),
    responseType: "blob",
  });
  return res.data;
}

async function exportCSVTemplate() {
  const res = await axios.get(`${base_url}alerts/export-template`, {
    headers: authService.authHeader(),
    responseType: "blob",
  });
  return res.data;
}

export default {
  list,
  attachAlerts,
  exportAlertsCSV,
  exportCSVTemplate,
};
