import Vue from "vue";
import service from "@/store/services/lot-service";

const state = {
  isLoadingData: false,
  filters: {
    status: "active",
  },
  list: [],
  meta: {},
};

const mutations = {
  SET_LIST: (state, payload) => {
    state.list = payload;
  },

  SET_META(state, data) {
    if (data.meta) {
      state.meta.page = data.meta.current_page;
      state.meta.itemsPerPage = data.meta.per_page;
      state.meta.lastPage = data.meta.last_page;
      state.meta.totalItems = data.meta.total;
    }
  },

  UPDATE_LOT(state, lot) {
    const item = state.list.find((item) => item.id === lot.id);
    if (item) Object.assign(item, lot);
  },

  SET_FILTERS(state, filters) {
    Vue.set(state.inbound, "filters", filters);
  },

  CLEAN_LIST(state) {
    Vue.set(state, "list", []);
  },
};

const actions = {
  list: (store, option) => {
    store.state.isLoadingData = true;
    return service
      .list(option)
      .then((payload) => {
        store.commit("SET_LIST", payload.lots);
        store.commit("SET_META", payload);
        store.state.isLoadingData = false;
      })
      .catch((error) => {
        store.state.isLoadingData = false;
        throw error;
      });
  },

  updatePrice: (store, payload) => {
    return service.updatePrice(payload).then((item) => {
      store.commit("UPDATE_LOT", item);
    });
  },

  exportCSV: async (store, option) => {
    return await service.exportCSV(option);
  },

  clearFilters: (store) => {
    store.commit("SET_FILTERS", {});
  },
};

const getters = {
  isLoadingData(state) {
    return state.isLoadingData;
  },

  list(state) {
    return state.list;
  },

  meta(state) {
    return state.meta;
  },

  filters(state) {
    return state.filters;
  },
};

const lots = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default lots;
