import axios from "@/helpers/axios";
import authService from "@/store/services/auth-service";

const base_url = process.env.VUE_APP_API_BASE_URL;

function list() {
  return axios
    .get(`${base_url}badges`, { headers: authService.authHeader() })
    .then((response) => {
      return response.data.badges;
    });
}
function getProductsAlert(type) {
  return axios
    .get(`${base_url}badges/products-alert/${type}`, {
      headers: authService.authHeader(),
    })
    .then((response) => {
      return response.data.products_alert;
    });
}

export default {
  list,
  getProductsAlert,
};
