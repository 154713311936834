import axios from "@/helpers/axios";

const base_url = process.env.VUE_APP_API_BASE_URL;

function authHeader() {
  let accessToken = localStorage.getItem("auth_access_token");

  if (accessToken) {
    return { Authorization: "Bearer " + accessToken };
  } else {
    return {};
  }
}

async function getUser() {
  return await axios.get(`${base_url}auth/user`, {
    headers: authHeader(),
  });
}

async function updatePassword(payload) {
  return await axios.post(`${base_url}users/change-my-password`, payload, {
    headers: authHeader(),
  });
}

async function updateMyStore(payload) {
  return await axios.post(`${base_url}users/update-my-store`, payload, {
    headers: authHeader(),
  });
}

async function sendResetPasswordMail(data) {
  return await axios.post(`${base_url}forgot-password`, data);
}

async function resetPassword(data) {
  return await axios.post(`${base_url}reset-password`, data);
}

export default {
  authHeader,
  getUser,
  updatePassword,
  sendResetPasswordMail,
  resetPassword,
  updateMyStore,
};
