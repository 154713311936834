import axios from "@/helpers/axios";
import authService from "@/store/services/auth-service";

const base_url = process.env.VUE_APP_API_BASE_URL;

async function list(params) {
  return axios
    .get(`${base_url}permissions`, {
      params,
      headers: authService.authHeader(),
    })
    .then((response) => {
      return response.data;
    });
}

export default {
  list,
};
