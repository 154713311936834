import axios from "@/helpers/axios";
import authService from "@/store/services/auth-service";

const base_url = process.env.VUE_APP_API_BASE_URL;

function getPriceSettings(params) {
  return axios
    .get(`${base_url}settings/price`, {
      params,
      headers: authService.authHeader(),
    })
    .then((response) => {
      return response.data.price_settings;
    });
}

function updatePriceSettings(payload) {
  return axios
    .put(`${base_url}settings/price`, payload, {
      headers: authService.authHeader(),
    })
    .then((response) => {
      return response.data.price_settings;
    });
}

export default {
  getPriceSettings,
  updatePriceSettings,
};
