import Vue from "vue";
import service from "@/store/services/hub-service";
import i18n from "@/i18n";

const state = {
  isVisibleDialog: false,
  isVisibleDeleteDialog: false,
  isVisibleTokenDialog: false,
  isLoadingHubs: false,
  isLoadingTypes: false,

  editedHub: {},

  types: [],
  listHubs: [],
  meta: {},
};

const mutations = {
  IS_VISIBLE_DIALOG(state, is_visible) {
    Vue.set(state, "isVisibleDialog", is_visible);
  },
  IS_VISIBLE_DELETE_DIALOG(state, is_visible) {
    Vue.set(state, "isVisibleDeleteDialog", is_visible);
  },
  IS_VISIBLE_TOKEN_DIALOG(state, is_visible) {
    Vue.set(state, "isVisibleTokenDialog", is_visible);
  },
  EDITED_INDEX(state, index) {
    Vue.set(state, "editedIndex", index);
  },
  EDITED_HUB(state, hub) {
    Vue.set(state, "editedHub", hub);
  },

  SET_HUBS(state, hubs) {
    state.listHubs = hubs;
  },
  SET_TYPES(state, hub_types) {
    state.types = hub_types;
  },

  ADD_HUB(state, hub) {
    state.listHubs.push(Object.assign({}, hub));
  },

  UPDATE_HUB(state, hub) {
    const item = state.listHubs.find((item) => item.id === hub.id);
    Object.assign(item, hub);
  },

  DELETE_HUB(state, hub) {
    state.listHubs.splice(state.listHubs.map((o) => o.id).indexOf(hub.id), 1);
  },

  SET_META(state, data) {
    if (data.meta) {
      state.meta.page = data.meta.current_page;
      state.meta.itemsPerPage = data.meta.per_page;
      state.meta.lastPage = data.meta.last_page;
      state.meta.totalItems = data.meta.total;
    }
  },
};

const actions = {
  openEditForm: (store, hub) => {
    store.commit("IS_VISIBLE_DIALOG", true);
    store.commit("EDITED_HUB", Object.assign({}, hub));
  },

  openTokenForm: (store, hub) => {
    store.commit("IS_VISIBLE_TOKEN_DIALOG", true);
    store.commit("EDITED_HUB", Object.assign({}, hub));
  },

  openDeleteForm: (store, hub) => {
    store.commit("IS_VISIBLE_DELETE_DIALOG", true);
    store.commit("EDITED_HUB", Object.assign({}, hub));
  },

  closeForm: (store) => {
    store.commit("IS_VISIBLE_DIALOG", false);
    store.commit("IS_VISIBLE_DELETE_DIALOG", false);
    store.commit("IS_VISIBLE_TOKEN_DIALOG", false);
    store.commit("EDITED_HUB", {});
  },

  list: (store, params) => {
    store.state.isLoadingHubs = true;
    return service
      .list(params)
      .then((data) => {
        store.commit("SET_HUBS", data.hubs);
        store.commit("SET_META", data);
        store.state.isLoadingHubs = false;
      })
      .catch((error) => {
        store.state.isLoadingHubs = false;
        throw error;
      });
  },

  types: (store) => {
    store.state.isLoadingTypes = true;
    return service
      .types()
      .then((data) => {
        store.commit("SET_TYPES", data.hub_types);
        store.state.isLoadingTypes = false;
      })
      .catch((error) => {
        store.state.isLoadingTypes = false;
        throw error;
      });
  },

  add: (store, hub) => {
    return service.add(hub).then((hub) => {
      store.commit("ADD_HUB", hub);
      store.dispatch("list", { page: 1, itemsPerPage: 30 });
    });
  },

  update: (store, hub) => {
    return service.update(hub).then((hub) => {
      store.commit("UPDATE_HUB", hub);
    });
  },

  destroy: (store) => {
    if (store.state.editedHub) {
      const hub = store.state.editedHub;
      return service.destroy(hub).then(() => {
        store.commit("DELETE_HUB", hub);
      });
    }
  },

  generateJWT: (store, hub) => {
    return service.generateJWT(hub);
  },

  updateExternalJWT: (store, hub) => {
    return service.updateExternalJWT(hub);
  },
};

const getters = {
  isVisibleDialog(state) {
    return state.isVisibleDialog;
  },

  isLoadingHubs(state) {
    return state.isLoadingHubs;
  },

  editedHub(state) {
    return state.editedHub;
  },

  listHubs(state) {
    return state.listHubs;
  },

  getTypes(state) {
    return state.types.map(function (item) {
      return { name: i18n.t(item), value: item };
    });
  },

  activeHubs(state) {
    return state.listHubs.filter((item) => {
      return item.status == "active";
    });
  },

  meta(state) {
    return state.meta;
  },
};

const hubs = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default hubs;
