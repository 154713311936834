// import vue from 'vue'
import router from "@/router";
import axios from "axios";
import Vue from "vue";
// import { response } from 'express'
import service from "@/store/services/auth-service";

const state = {
  isAuthenticated: localStorage.getItem("auth_access_token") !== null,
  accessToken: localStorage.getItem("auth_access_token"),
  user: JSON.parse(localStorage.getItem("user")),
};

const mutations = {
  IS_AUTHENTICATED(state, is_auth) {
    Vue.set(state, "isAuthenticated", is_auth);
  },
  ACCESS_TOKEN(state, access_token) {
    localStorage.setItem("auth_access_token", access_token);
    Vue.set(state, "accessToken", access_token);
  },
  SET_USER(state, user) {
    localStorage.removeItem("user");
    localStorage.setItem("user", JSON.stringify(user));
    Vue.set(state, "user", user);
  },
};

const getters = {
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  accessToken(state) {
    return state.accessToken;
  },
  getUser(state) {
    return state.user ? state.user : JSON.parse(localStorage.getItem("user"));
  },
  getPermissions(state) {
    const user = state.user
      ? state.user
      : JSON.parse(localStorage.getItem("user"));
    const permissions = [];
    if (user && user.permissions) {
      for (const [key, value] of Object.entries(user.permissions)) {
        permissions.push({ id: key, name: value });
      }
    }
    return permissions;
  },
};

const actions = {
  checkAuth(store) {
    var token = localStorage.getItem("auth_access_token");

    if (token) {
      store.commit("IS_AUTHENTICATED", true);
      store.commit("ACCESS_TOKEN", token);
    } else {
      store.commit("IS_AUTHENTICATED", false);
      store.commit("ACCESS_TOKEN", "");
    }
  },

  login(store, credential) {
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_API_BASE_URL + "login", credential)
        .then(async (response) => {
          store.commit("IS_AUTHENTICATED", true);
          store.commit("ACCESS_TOKEN", response.data.token);
          store.commit("SET_USER", response.data.user);

          router.go(0);
          // return router.push({ name: "dashboard", replace: true  });
        })
        .catch(function (error) {
          reject(error.response?.data?.message);
        });
    });
  },

  refreshUser(store) {
    return service.getUser().then((response) => {
      store.commit("SET_USER", response.data.user);
    });
  },

  logout(store) {
    store.commit("IS_AUTHENTICATED", false);
    store.commit("ACCESS_TOKEN", "");
    return router.push({ name: "login" });
  },

  updatePassword: (store, user) => {
    return service.updatePassword(user);
  },

  sendResetPasswordMail: (store, data) => {
    return service.sendResetPasswordMail(data);
  },

  resetPassword: (store, data) => {
    return service.resetPassword(data);
  },

  updateMyStore: (store, payload) => {
    return service.updateMyStore(payload).then(() => {
      router.push({ name: "dashboard" });
      setTimeout(() => router.go(0), 1000);
    });
  },
};

const auth = {
  state,
  getters,
  actions,
  mutations,
};

export default auth;
