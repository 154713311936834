<template>
  <vue-ctk-date-time-picker
    v-model="date"
    :label="label"
    no-label
    class="mb-2"
    :format="'YYYY-MM-DD HH:mm:ss'"
    :formatted="'DD MMMM YYYY HH:mm'"
    :first-day-of-week="1"
    minute-interval="5"
    color="#5E2EBE"
    button-color="#5E2EBE"
    :min-date="minDate"
    :locale="lang"
    :button-now-translation="$t('now')"
    auto-close
    :no-value-to-custom-elem="true"
    @input="dateFormatted = formatDate(date)"
  >
    <v-text-field
      v-model="dateFormatted"
      outlined
      :label="label"
      dense
      hint="DD/MM/YYYY hh:mm format"
      persistent-hint
      append-icon="mdi-calendar"
      :rules="[(value) => !!value || 'Required.', ...(rules ?? [])]"
    ></v-text-field>
  </vue-ctk-date-time-picker>
</template>
<script>
import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css";
import moment from "moment";

export default {
  name: "DateTimePicker",

  props: {
    label: String,
    value: String,
    rules: Array,
  },

  components: {
    "vue-ctk-date-time-picker": () => import("vue-ctk-date-time-picker"),
  },
  data() {
    return {
      date: this.value,
      dateFormatted: this.formatDate(this.value),
      minDate: moment().add(10, "minutes").format("YYYY-MM-DD HH:mm"),
      lang: this.$i18n.locale,
    };
  },
  watch: {
    date() {
      this.$emit("input", this.date);
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return null;
      return moment(date).format("DD/MM/YYYY HH:mm");
    },
  },
};
</script>