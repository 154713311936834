import service from '../services/delivery-types-service';

const state = {
  list: [],
  isLoading: false,
}

const mutations = {
  SET_DELIVERY_TYPES: (state, payload) => {
    state.list = payload;
  },
}

const actions = {
  list: (store) => {
    store.state.isLoading = true;
    return service.getDeliveryTypes().then((payload) => {
      store.commit("SET_DELIVERY_TYPES", payload);
      store.state.isLoading = false;
    });
  },
};

const getters = {
  isLoading(state) {
    return state.isLoading;
  },
  list(state) {
    return state.list;
  },
};

const deliveryTypes = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default deliveryTypes;