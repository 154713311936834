import service from "@/store/services/order-origin-service";

const state = {
  isLoading: false,
  list: [],
  listNames: [],
};

const mutations = {
  SET_ORDER_ORIGINS: (state, payload) => {
    state.list = payload;
  },
  SET_ORDER_ORIGINS_NAMES: (state, payload) => {
    state.listNames = payload;
  },
};

const actions = {
  list: (store) => {
    store.state.isLoading = true;
    return service.list().then((payload) => {
      store.commit("SET_ORDER_ORIGINS", payload);
      store.state.isLoading = false;
    });
  },

  listNames: (store) => {
    store.state.isLoading = true;
    return service.listNames().then((payload) => {
      store.commit("SET_ORDER_ORIGINS_NAMES", payload);
      store.state.isLoading = false;
    });
  },
};

const getters = {
  isLoading(state) {
    return state.isLoading;
  },

  getOrderOrigins(state) {
    return state.list;
  },

  getOrderOriginsNames(state) {
    return state.listNames;
  },
};

const orderOrigins = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default orderOrigins;
