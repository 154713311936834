import Vue from "vue";
import service from "@/store/services/stock-management-batch-service";

const state = {
  isLoading: false,
  product: {},

  list: [],

  meta: {},
  filters: { itemsPerPage: 30 },
  selected: 0,
};

const mutations = {
  SET_LIST(state, payload) {
    Vue.set(state, "list", payload);
  },
  SET_PRODUCT(state, product) {
    Vue.set(state, "product", product);
  },

  SET_META(state, data) {
    state.meta.page = data.meta.current_page;
    state.meta.itemsPerPage = data.meta.per_page;
    state.meta.lastPage = data.meta.last_page;
    state.meta.totalItems = data.meta.total;
    state.meta.manual_cancellation = data.meta.manual_cancellation;
  },

  SET_FILTERS(state, payload) {
    Vue.set(state, "filters", payload);
  },

  SET_SELECTED(state, selected) {
    state.selected = selected;
  },

  CLEAN_LIST(state) {
    Vue.set(state, "list", []);
  },
};

const actions = {
  setFilters: (store, payload) => {
    store.commit("SET_FILTERS", payload);
  },

  list: (store, params) => {
    store.state.isLoading = true;
    return service
      .list(params)
      .then((data) => {
        store.commit("SET_LIST", data.stockAdjustmentsLot);
        store.commit("SET_META", data);
        store.state.isLoading = false;
      })
      .catch((error) => {
        store.state.isLoading = false;
        throw error;
      });
  },
  exportCSVResult: (store, option) => {
    return service.exportCSVResult(option);
  },
};

const getters = {
  isLoading(state) {
    return state.isLoading;
  },

  product(state) {
    return state.product;
  },

  meta(state) {
    return state.meta;
  },

  filters(state) {
    return state.filters;
  },

  selected(state) {
    return state.selected;
  },

  list(state) {
    return state.list;
  },
};

const stockManagementsBatch = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default stockManagementsBatch;
