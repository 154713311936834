import service from "@/store/services/city-service";

const state = {
  isLoadingCities: false,
  listCities: [],
};

const mutations = {
  SET_CITIES: (state, cities) => {
    state.listCities = cities;
  },
  APPEND_CITIES: (state, city) => {
    state.listCities.push(city);
  },
  RESET_CITIES: (state) => {
    state.listCities = [];
  },
};

const actions = {
  list: (store, wilaya_id) => {
    store.state.isLoadingCities = true;
    return service.list(wilaya_id).then((cities) => {
      store.commit("SET_CITIES", cities);
      store.state.isLoadingCities = false;
    });
  },
  search: (store, payload) => {
    if (!payload.countryCode || !payload.searchQuery) return;
    store.state.isLoadingCities = true;
    return service
      .search(payload.countryCode, payload.searchQuery)
      .then((cities) => {
        store.commit("SET_CITIES", cities);
        store.state.isLoadingCities = false;
      });
  },
  city: (store, payload) => {
    if (!payload.city_id) return;
    store.state.isLoadingCities = true;
    return service.getCity(payload.city_id).then((city) => {
      store.commit("APPEND_CITIES", city);
      store.state.isLoadingCities = false;
    });
  },
};

const getters = {
  isLoadingCities(state) {
    return state.isLoadingCities;
  },

  listCities(state) {
    return state.listCities;
  },
};

const cities = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default cities;
