import Vue from "vue";
import service from "@/store/services/statistic-service";

const state = {
  isLoadingData: false,
  listProducts: [],
  meta: {},
};

const mutations = {
  SET_DATA: (state, payload) => {
    state.listProducts = payload;
  },

  SET_META(state, data) {
    if (data.meta) {
      state.meta.page = data.meta.current_page;
      state.meta.itemsPerPage = data.meta.per_page;
      state.meta.lastPage = data.meta.last_page;
      state.meta.totalItems = data.meta.total;
    }
  },

  CLEAN_LIST(state) {
    Vue.set(state, "listProducts", []);
  },
};

const actions = {
  list: (store, option) => {
    store.state.isLoadingData = true;
    return service.list(option).then((payload) => {
      store.commit("SET_DATA", payload.products);
      store.commit("SET_META", payload);
      store.state.isLoadingData = false;
    });
  },

  exportCSV: (store, option) => {
    return service.exportCSV(option);
  },
};

const getters = {
  isLoadingData(state) {
    return state.isLoadingData;
  },

  listProducts(state) {
    return state.listProducts;
  },
  meta(state) {
    return state.meta;
  },
};

const statistics = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default statistics;
