import service from "@/store/services/dash-service";

const state = {
  isLoading: false,
  orderStatusInfo: [],
  productsOutStock: [],
};

const mutations = {
  SET_ORDER_STATUS_INFO(state, payload) {
    state.orderStatusInfo = payload;
  },
  SET_PRODUCTS_OUT_STOCK(state, payload) {
    state.productsOutStock = payload;
  },
};

const actions = {
  getDashInfo: (store, params) => {
    store.state.isLoading = true;
    return service.getDashInfo(params).then((payload) => {
      store.commit("SET_ORDER_STATUS_INFO", payload.data.orderStatus);
      store.commit("SET_PRODUCTS_OUT_STOCK", payload.data.products);
      store.state.isLoading = false;
    });
  },
};

const getters = {
  isLoading(state) {
    return state.isLoading;
  },

  getOrderStatusInfo(state) {
    return state.orderStatusInfo;
  },
  getProductsOutStock(state) {
    return state.productsOutStock;
  },
};

const dash = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default dash;
