import axios from "@/helpers/axios";
import authService from "@/store/services/auth-service";

const base_url = process.env.VUE_APP_API_BASE_URL;

async function list(params) {
  const res = await axios.get(`${base_url}coupons`, {
    headers: authService.authHeader(),
    params,
  });
  return res.data;
}

async function add(coupon) {
  const res = await axios.post(`${base_url}coupons`, coupon, {
    headers: authService.authHeader(),
  });
  return res.data;
}

async function clone(id) {
  const {
    data: { coupon },
  } = await axios.post(
    `${base_url}coupons/${id}/clone`,
    {},
    {
      headers: authService.authHeader(),
    }
  );
  return coupon;
}

async function update(coupon) {
  const res = await axios.put(`${base_url}coupons/${coupon.id}`, coupon, {
    headers: authService.authHeader(),
  });
  return res.data.coupon;
}

async function toggleStatus(coupon) {
  const res = await axios.put(
    `${base_url}coupons/toggle_status/${coupon.id}`,
    {},
    { headers: authService.authHeader() }
  );
  return res.data.coupon;
}

async function destroy(coupon) {
  return await axios.delete_method(`${base_url}coupons/${coupon.id}`, {
    headers: authService.authHeader(),
  });
}
async function show(id) {
  const res = await axios.get(`${base_url}coupons/${id}`, {
    headers: authService.authHeader(),
  });
  return res.data.coupon;
}
export default {
  toggleStatus,
  list,
  add,
  clone,
  update,
  destroy,
  show,
};
