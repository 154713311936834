import axios from "@/helpers/axios";
import authService from "@/store/services/auth-service";

const base_url = process.env.VUE_APP_API_BASE_URL;

async function list(params) {
  const res = await axios.get(
    `${base_url}stock-management/details-product/${params.product_id}`,
    {
      params,
      headers: authService.authHeader(),
    }
  );
  return res.data;
}

async function getDetailsAtDate(params) {
  const res = await axios.get(
    `${base_url}stock-management/details-product/${params.id}/quantities-in-stock`,
    {
      params: { date: params.date },
      headers: authService.authHeader(),
    }
  );
  return res.data;
}

async function getOutboundAtDate(params) {
  const res = await axios.get(
    `${base_url}stock-management/details-product/${params.product_id}/outbound`,
    {
      params: { date: params.date },
      headers: authService.authHeader(),
    }
  );
  return res.data;
}

async function getAdjustmentAtDate(params) {
  const res = await axios.get(
    `${base_url}stock-management/details-product/${params.id}/adjustment`,
    {
      params: { date: params.date },
      headers: authService.authHeader(),
    }
  );
  return res.data;
}

async function getInboundAtDate(params) {
  const res = await axios.get(
    `${base_url}stock-management/details-product/${params.id}/inbound`,
    {
      params: { date: params.date },
      headers: authService.authHeader(),
    }
  );
  return res.data;
}

async function getTransfersAtDate(params) {
  const res = await axios.get(
    `${base_url}stock-management/details-product/${params.id}/transfer`,
    {
      params: { date: params.date },
      headers: authService.authHeader(),
    }
  );
  return res.data;
}
async function getReservedAtDate(params) {
  const res = await axios.get(
    `${base_url}stock-management/details-product/${params.id}/reserved`,
    {
      params: { date: params.date },
      headers: authService.authHeader(),
    }
  );
  return res.data;
}

async function downloadCSVResult(options) {
  const res = await axios.get(
    `${base_url}stock-management/${options.product_id}/export`,
    {
      params: options,
      headers: authService.authHeader(),
      responseType: "blob",
    }
  );
  return res.data;
}

export default {
  list,
  getDetailsAtDate,
  getOutboundAtDate,
  getAdjustmentAtDate,
  getInboundAtDate,
  getTransfersAtDate,
  downloadCSVResult,
  getReservedAtDate,
};
