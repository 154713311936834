import axios from "@/helpers/axios";
import authService from "@/store/services/auth-service";

const base_url = process.env.VUE_APP_API_BASE_URL;

function getDashInfo(params) {
  return axios
    .get(`${base_url}info-dash`, { params, headers: authService.authHeader() })
    .then((response) => {
      return response.data;
    });
}

export default {
  getDashInfo,
};
