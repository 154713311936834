import axios from "@/helpers/axios";
import authService from "@/store/services/auth-service";

const base_url = process.env.VUE_APP_API_BASE_URL;

async function list(params) {
  const res = await axios.get(`${base_url}stock-management`, {
    params,
    headers: authService.authHeader(),
  });
  return res.data;
}

async function exportResultCSV(option) {
  const res = await axios.get(`${base_url}stock-management/result_export`, {
    params: { ...option, details_export: true },
    headers: authService.authHeader(),
    responseType: "blob",
  });
  return res.data;
}

async function exportDetailstCSV(option) {
  const res = await axios.get(`${base_url}stock-management/details_export`, {
    params: { ...option, details_export: true },
    headers: authService.authHeader(),
    responseType: "blob",
  });
  return res.data;
}

export default {
  list,
  exportResultCSV,
  exportDetailstCSV,
};
