<template>
  <base-material-card
    :color="color"
    class="px-5 py-3"
  >
    <template v-slot:heading>
      <div class="display-2 font-weight-light">
        {{ data.title }}
      </div>
      <!-- 
          <div class="subtitle-1 font-weight-light">
              {{data.subtitle}}
          </div> -->
    </template>
    <v-card-text>
      <base-pie-chart
        :data="data"
        :options="options"
      />
    </v-card-text>
  </base-material-card>
</template>

<script>
export default {
  name: "MaterialChartCard",

  inheritAttrs: false,

  props: {
    color: {
      type: String,
      default: () => "success",
    },
    data: {
      type: Object,
      default: () => ({}),
    },

    options: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
