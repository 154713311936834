import service from "@/store/services/product-alert-service";
import Vue from "vue";

const state = {
  isLoading: false,
  list: [],
  item: {},
  meta: {},
  params: {},

  isVisibleShow: false,
};

const mutations = {
  IS_VISIBLE_SHOW(state, is_visible) {
    Vue.set(state, "isVisibleShow", is_visible);
  },

  SET_LIST(state, payload) {
    state.list = payload;
  },

  SET_ITEM(state, payload) {
    state.item = payload;
  },

  UPDATE_ITEM(state, payload) {
    const item = state.list.find((item) => item.id === payload.id);
    if (item) {
      Object.assign(item, payload);
    }
  },

  SET_META(state, data) {
    state.meta.page = data.meta.current_page;
    state.meta.itemsPerPage = data.meta.per_page;
    state.meta.lastPage = data.meta.last_page;
    state.meta.totalItems = data.meta.total;
    // Vue.set(state, "listProducts", products);
  },

  SET_PARAMS(state, payload) {
    Vue.set(state, "params", payload);
  },

  CLEAN_LIST(state) {
    Vue.set(state, "list", []);
  },
};

const actions = {
  openShowForm: async (store, payload) => {
    store.commit("SET_ITEM", payload);
    store.commit("IS_VISIBLE_SHOW", true);
  },

  closeForm: (store) => {
    store.commit("IS_VISIBLE_SHOW", false);
  },

  list: (store, params) => {
    store.state.isLoading = true;
    return service.list({ ...params, has_parent: false }).then((data) => {
      store.commit("SET_LIST", data.products);
      store.commit("SET_META", data);
      store.state.isLoading = false;
    });
  },

  attachAlerts: (store, payload) => {
    return service.attachAlerts(payload).then((payload) => {
      store.commit("UPDATE_ITEM", payload);
    });
  },

  exportAlertsCSV: (store, option) => {
    return service.exportAlertsCSV(option);
  },

  setParams: (store, params) => {
    store.commit("SET_PARAMS", params);
  },

  clean: (store) => {
    store.commit("SET_PARAMS", {});
    store.commit("CLEAN_LIST");
  },
};

const getters = {
  isLoading(state) {
    return state.isLoading;
  },

  getList(state) {
    return state.list;
  },

  getItem(state) {
    return state.item;
  },

  meta(state) {
    return state.meta;
  },

  params(state) {
    return state.params;
  },
};

const productAlerts = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default productAlerts;
