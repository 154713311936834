import service from "@/store/services/inbound-status-service";

const state = {
  isLoadingStatuses: false,
  listStatuses: [],
};

const mutations = {
  SET_STATUSES: (state, statuses) => {
    state.listStatuses = statuses;
  },
};

const actions = {
  list: (store) => {
    store.state.isLoadingStatuses = true;
    return service.list().then((statuses) => {
      store.commit("SET_STATUSES", statuses);
      store.state.isLoadingStatuses = false;
    });
  },
};

const getters = {
  listStatuses(state) {
    return state.listStatuses;
  },
};

const inboundStatuses = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default inboundStatuses;
