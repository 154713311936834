import service from "@/store/services/sync-product-service";

const state = {
  isLoading: false,

  data: {},
};

const mutations = {
  SET_DATA(state, data) {
    state.data = data;
  },
  SET_IS_LOADING(state, val) {
    state.isLoading = val;
  },
};

const actions = {
  syncWithExpress: (store, payload) => {
    store.commit("SET_IS_LOADING", true);
    return service.syncWithExpress(payload).then((data) => {
      store.commit("SET_DATA", data);
      store.commit("SET_IS_LOADING", false);
    });
  },
};

const getters = {
  data(state) {
    return state.data;
  },
};

const syncProducts = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default syncProducts;
