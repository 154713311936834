import axios from "@/helpers/axios";
import baseAxios from "axios";
import authService from "@/store/services/auth-service";

const base_url = process.env.VUE_APP_API_BASE_URL;

async function list(options) {
  const res = await axios.get(`${base_url}products`, {
    params: options,
    headers: authService.authHeader(),
  });
  return res.data;
}
async function listBarcodes(options) {
  const res = await axios.get(`${base_url}barcodes`, {
    params: options,
    headers: authService.authHeader(),
  });
  return res.data;
}

async function downloadCSV(option) {
  const res = await axios.get(`${base_url}products_export_csv`, {
    params: option,
    headers: authService.authHeader(),
    responseType: "blob",
  });
  return res.data;
}

async function downloadTemplate() {
  const res = await axios.get(`${base_url}products_import_csv`, {
    headers: authService.authHeader(),
    responseType: "blob",
  });
  return res.data;
}

async function downloadFailedResult(id) {
  const res = await axios.get(`${base_url}products/import_errors_file/${id}`, {
    headers: authService.authHeader(),
    responseType: "blob",
  });
  return res.data;
}

async function show(options) {
  const res = await axios.get(`${base_url}products/${options.id}`, {
    headers: authService.authHeader(),
  });
  return res.data.product;
}

async function add(product) {
  const res = await axios.post(`${base_url}products`, product, {
    headers: {
      "Content-Type": "multipart/form-data",
      ...authService.authHeader(),
    },
  });
  return res.data.product;
}

async function store(product) {
  const res = await axios.post(`${base_url}products`, product, {
    headers: {
      "Content-Type": "multipart/form-data",
      ...authService.authHeader(),
    },
  });
  return res.data.product;
}

async function put(product) {
  const res = await axios.put(`${base_url}products/${product.id}`, product, {
    headers: {
      "Content-Type": "application/json",
      ...authService.authHeader(),
    },
  });
  return res.data.product;
}

async function saveImage(product, image) {
  const res = await baseAxios.post(
    `${base_url}products/${product.id}/upload_image`,
    image,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        ...authService.authHeader(),
      },
    }
  );
  return res.data.product;
}

async function update(product) {
  const res = await axios.post(
    `${base_url}products/update/${product.id}`,
    product,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        ...authService.authHeader(),
      },
    }
  );
  return res.data.product;
}

function destroy(product) {
  return axios.delete_method(`${base_url}products/${product.id}`, {
    headers: authService.authHeader(),
  });
}

async function uploadCSV(csv) {
  const res = await axios.post(`${base_url}products/upload_csv`, csv, {
    headers: {
      "Content-Type": "multipart/form-data",
      ...authService.authHeader(),
    },
  });
  return res.data;
}

async function uploadAlertsCSV(csv) {
  const res = await axios.post(`${base_url}products/upload_alerts_csv`, csv, {
    headers: {
      "Content-Type": "multipart/form-data",
      ...authService.authHeader(),
    },
  });
  return res.data;
}

async function getLastProductCode() {
  const res = await axios.get(
    `${process.env.VUE_APP_API_BASE_URL}last_product_code`,
    { headers: authService.authHeader() }
  );
  return res.data.code;
}

async function generateBarcode() {
  const res = await axios.get(
    `${process.env.VUE_APP_API_BASE_URL}barcodes/generate`,
    { headers: authService.authHeader() }
  );

  return res.data.barcode;
}

async function getImportStatus(id) {
  const res = await axios.get(`${base_url}products/import_status/${id}`, {
    headers: authService.authHeader(),
  });
  return res.data;
}
async function updateGroupedProduct(product) {
  const res = await axios.put(`${base_url}grouped-products`, product, {
    headers: {
      "Content-Type": "multipart/form-data",
      ...authService.authHeader(),
    },
  });
  return res.data.product;
}
async function storeGroupedProduct(product) {
  if (!product.id) {
    const res = await axios.post(`${base_url}grouped-products`, product, {
      headers: {
        "Content-Type": "multipart/form-data",
        ...authService.authHeader(),
      },
    });
    return res.data.product;
  }

  const res = await axios.post(
    `${base_url}grouped-products/${product.id}?_method=PUT`,
    product,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        ...authService.authHeader(),
      },
    }
  );

  return res.data.product;
}

async function movingQuantities(options) {
  const res = await axios.get(
    `${base_url}products/${options.product_id}/hubs/${options.hub_id}/moving-quantities`,
    {
      params: options,
      headers: authService.authHeader(),
    }
  );
  return res.data;
}
async function getDiscount(id, hub) {
  const res = await axios.get(`${base_url}products/${id}/discount?hub=${hub}`, {
    headers: authService.authHeader(),
  });

  return res.data.promotion;
}

async function getDiscounts(id, hub) {
  const res = await axios.get(
    `${base_url}products/${id}/discount?hub=${hub}`,
    {
      headers: authService.authHeader(),
    },
    true
  );

  return res.data.promotions;
}

export default {
  list,
  listBarcodes,
  downloadCSV,
  downloadTemplate,
  downloadFailedResult,
  storeGroupedProduct,
  updateGroupedProduct,
  show,
  add,
  store,
  put,
  update,
  saveImage,
  destroy,
  uploadCSV,
  uploadAlertsCSV,
  getLastProductCode,
  generateBarcode,
  getImportStatus,
  getDiscounts,
  movingQuantities,
  getDiscount,
};
