import service from "@/store/services/country-service";

const state = {
  isLoadingCountries: false,
  listCountries: [],
};

const mutations = {
  SET_COUNTRIES: (state, countries) => {
    state.listCountries = countries;
  },
};

const actions = {
  list: (store) => {
    store.state.isLoadingCountries = true;
    return service.list().then((countries) => {
      store.commit("SET_COUNTRIES", countries);
      store.state.isLoadingCountries = false;
    });
  },
};

const getters = {
  isLoadingCountries(state) {
    return state.isLoadingCountries;
  },

  listCountries(state) {
    return state.listCountries;
  },
};

const countries = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default countries;
