import service from "@/store/services/setting-service";

const state = {
  isLoading: false,
  priceSettings: {},
};

const mutations = {
  SET_PRICE_SETTINGS: (state, payload) => {
    state.priceSettings = payload;
  },
};

const actions = {
  getPriceSettings: (store) => {
    store.state.isLoading = true;
    return service.getPriceSettings().then((payload) => {
      store.commit("SET_PRICE_SETTINGS", payload);
      store.state.isLoading = false;
    });
  },

  updatePriceSettings: (store, payload) => {
    store.state.isLoading = true;
    return service.updatePriceSettings(payload).then((payload) => {
      store.commit("SET_PRICE_SETTINGS", payload);
      store.state.isLoading = false;
    });
  },
};

const getters = {
  isLoading(state) {
    return state.isLoading;
  },

  priceSettings(state) {
    return state.priceSettings;
  },
};

const settings = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default settings;
