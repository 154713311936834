import service from "@/store/services/wilaya-service";

const state = {
  isLoadingWilayas: false,
  listWilayas: [],
};

const mutations = {
  SET_WILAYAS: (state, wilayas) => {
    state.listWilayas = wilayas;
  },
};

const actions = {
  list: (store) => {
    store.state.isLoadingWilayas = true;
    return service.list().then((wilayas) => {
      store.commit("SET_WILAYAS", wilayas);
      store.state.isLoadingWilayas = false;
    });
  },
};

const getters = {
  isLoadingWilayas(state) {
    return state.isLoadingWilayas;
  },

  listWilayas(state) {
    return state.listWilayas;
  },
};

const wilayas = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default wilayas;
