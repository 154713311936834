import axios from "@/helpers/axios";
import authService from "@/store/services/auth-service";

const base_url = process.env.VUE_APP_API_BASE_URL;

async function list() {
  const res = await axios.get(`${base_url}stores`, {
    headers: authService.authHeader(),
  });
  return res.data.stores;
}
async function add(store) {
  const res = await axios.post(`${base_url}stores`, store, {
    headers: authService.authHeader(),
  });
  return res.data.store;
}

async function update(store) {
  const res = await axios.put(`${base_url}stores/${store.id}`, store, {
    headers: authService.authHeader(),
  });
  return res.data.store;
}

function destroy(store) {
  return axios.delete_method(`${base_url}stores/${store.id}`, {
    headers: authService.authHeader(),
  });
}

export default {
  list,
  add,
  update,
  destroy,
};
