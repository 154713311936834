import service from "@/store/services/attribute-service";

const state = {
  isLoadingAttributes: false,
  listAttributes: [],
};

const mutations = {
  SET_ATTRIBUTES(state, list_attributes) {
    state.listAttributes = list_attributes;
  },

  ADD_ATTRIBUTE(state, attribute) {
    state.listAttributes.push(attribute);
  },

  UPDATE_ATTRIBUTE(state, attribute) {
    const item = state.listAttributes.find((item) => item.id === attribute.id);
    Object.assign(item, attribute);
  },

  DELETE_ATTRIBUTE(state, attribute) {
    state.listAttributes.splice(
      state.listAttributes.map((o) => o.id).indexOf(attribute.id),
      1
    );
  },
};

const actions = {
  list: (store, params) => {
    store.state.isLoadingAttributes = true;
    return service.list(params).then((list_attributes) => {
      store.commit("SET_ATTRIBUTES", list_attributes);
      store.state.isLoadingAttributes = false;
    });
  },

  add: (store, payload) => {
    return service.add(payload).then((attribute) => {
      store.commit("ADD_ATTRIBUTE", attribute);
      store.dispatch("list", { page: 1, itemsPerPage: 30 });
    });
  },

  update: (store, payload) => {
    return service.update(payload).then((attribute) => {
      store.commit("UPDATE_ATTRIBUTE", attribute);
    });
  },

  destroy: (store, payload) => {
    return service.destroy(payload).then(() => {
      store.commit("DELETE_ATTRIBUTE", payload);
    });
  },
};

const getters = {
  isLoadingAttributes(state) {
    return state.isLoadingAttributes;
  },

  listAttributes(state) {
    return state.listAttributes;
  },
};

const attributes = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default attributes;
