import axios from "@/helpers/axios";
import authService from "@/store/services/auth-service";

const base_url = process.env.VUE_APP_API_BASE_URL;

async function list(params) {
  const res = await axios.get(
    `${base_url}stock-management/details-product/${params.product_id}/lots`,
    {
      params,
      headers: authService.authHeader(),
    }
  );
  return res.data;
}
async function exportCSVResult(options) {
  const res = await axios.get(
    `${base_url}stock-management/${options.product_id}/batch_export`,
    {
      params: options,
      headers: authService.authHeader(),
      responseType: "blob",
    }
  );
  return res.data;
}

export default {
  list,
  exportCSVResult,
};
