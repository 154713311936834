import axios from "@/helpers/axios";
import authService from "@/store/services/auth-service";

const base_url = process.env.VUE_APP_API_BASE_URL;

async function list(options) {
  const res = await axios.get(`${base_url}users`, {
    params: options,
    headers: authService.authHeader(),
  });
  return res.data;
}
async function add(user) {
  const res = await axios.post(`${base_url}users`, user, {
    headers: {
      "Content-Type": "multipart/form-data",
      ...authService.authHeader(),
    },
  });
  return res.data.user;
}

async function update(user) {
  const res = await axios.post(`${base_url}users/update/${user.id}`, user, {
    headers: {
      "Content-Type": "multipart/form-data",
      ...authService.authHeader(),
    },
  });
  return res.data.user;
}

function destroy(user) {
  return axios.delete_method(`${base_url}users/${user.id}`, {
    headers: authService.authHeader(),
  });
}

async function updateStatus(user) {
  const res = await axios.post(`${base_url}users/update-status`, user, {
    headers: authService.authHeader(),
  });
  return res.data.user;
}

async function updatePW(user) {
  return await axios.post(`${base_url}users/change-password`, user, {
    headers: authService.authHeader(),
  });
}

export default {
  list,
  add,
  update,
  destroy,
  updateStatus,
  updatePW,
};
