import axios from "@/helpers/axios";
import authService from "@/store/services/auth-service";

const base_url = process.env.VUE_APP_API_BASE_URL;

async function list(params) {
  return axios
    .get(`${base_url}roles`, { params, headers: authService.authHeader() })
    .then((response) => {
      return response.data;
    });
}

async function show(role) {
  return axios
    .get(`${base_url}roles/${role.id}`, { headers: authService.authHeader() })
    .then((response) => {
      return response.data;
    });
}

async function add(role) {
  return axios
    .post(`${base_url}roles`, role, { headers: authService.authHeader() })
    .then((response) => {
      return response.data.role;
    });
}

async function update(role) {
  return axios
    .put(`${base_url}roles/${role.id}`, role, {
      headers: authService.authHeader(),
    })
    .then((response) => {
      return response.data.role;
    });
}

async function destroy(role) {
  return axios
    .delete_method(`${base_url}roles/${role.id}`, {
      headers: authService.authHeader(),
    })
    .then((response) => {
      return response.data.role;
    });
}

export default {
  list,
  show,
  add,
  update,
  destroy,
};
