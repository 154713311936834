import axios from "@/helpers/axios";
import authService from "@/store/services/auth-service";

const base_url = process.env.VUE_APP_API_BASE_URL;

function list(option) {
  return axios
    .get(`${base_url}statistics/products`, {
      params: option,
      headers: authService.authHeader(),
    })
    .then((response) => {
      return response.data;
    });
}

async function exportCSV(option) {
  const res = await axios.get(`${base_url}statistics/export_products`, {
    params: option,
    headers: authService.authHeader(),
    responseType: "blob",
  });
  return res.data;
}

export default {
  list,
  exportCSV,
};
