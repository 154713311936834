import Vue from "vue";
import service from "@/store/services/store-service";

const state = {
  isVisibleDialog: false,
  isVisibleDeleteDialog: false,
  isLoadingStores: false,

  editedStore: {},
  listStores: [],
};

const mutations = {
  IS_VISIBLE_DIALOG(state, is_visible) {
    Vue.set(state, "isVisibleDialog", is_visible);
  },
  IS_VISIBLE_DELETE_DIALOG(state, is_visible) {
    Vue.set(state, "isVisibleDeleteDialog", is_visible);
  },
  EDITED_INDEX(state, index) {
    Vue.set(state, "editedIndex", index);
  },
  EDITED_STORE(state, payload) {
    Vue.set(state, "editedStore", payload);
  },

  SET_STORES(state, payload) {
    state.listStores = payload;
  },

  ADD_STORE(state, payload) {
    state.listStores.push(Object.assign({}, payload));
  },

  UPDATE_STORE(state, payload) {
    const item = state.listStores.find((item) => item.id === payload.id);
    Object.assign(item, payload);
  },

  DELETE_STORE(state, payload) {
    state.listStores.splice(
      state.listStores.map((o) => o.id).indexOf(payload.id),
      1
    );
  },
};

const actions = {
  openEditForm: (store, payload) => {
    store.commit("IS_VISIBLE_DIALOG", true);
    store.commit("EDITED_STORE", Object.assign({}, payload));
  },

  openDeleteForm: (store, payload) => {
    store.commit("IS_VISIBLE_DELETE_DIALOG", true);
    store.commit("EDITED_STORE", Object.assign({}, payload));
  },

  closeForm: (store) => {
    store.commit("IS_VISIBLE_DIALOG", false);
    store.commit("IS_VISIBLE_DELETE_DIALOG", false);
    store.commit("EDITED_STORE", {});
  },

  list: (store) => {
    store.state.isLoadingStores = true;
    service.list().then((payload) => {
      store.commit("SET_STORES", payload);
      store.state.isLoadingStores = false;
    });
  },

  add: (store, payload) => {
    service.add(payload).then((item) => {
      store.commit("ADD_STORE", item);
    });
  },

  update: (store, payload) => {
    service.update(payload).then((item) => {
      store.commit("UPDATE_STORE", item);
    });
  },

  destroy: (store) => {
    if (store.state.editedStore) {
      const item = store.state.editedStore;
      service.destroy(item).then(() => {
        store.commit("DELETE_STORE", item);
      });
    }
  },
};

const getters = {
  isVisibleDialog(state) {
    return state.isVisibleDialog;
  },

  editedStore(state) {
    return state.editedStore;
  },

  listStores(state) {
    return state.listStores;
  },
};

const stores = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default stores;
